import React from 'react';
import {useLocation} from 'react-router-dom';

import { mergeClasses } from 'classify';
import defaultClasses from './footer.module.scss';
import { IconM } from '../Icon';

const Footer = (props) => {
  const classes = mergeClasses(defaultClasses, props.classes);
  const location = useLocation();

  return (
    <footer className={`footer mt-auto footer-light shadow  ${classes.root}`}>
      {location.pathname === '/home' ?
        <>
          <div className={`container-fluid ${classes.menu}`}>
            <div className="row">
              <div className={`col-md-6 small d-flex align-items-center ${classes.col_3}`}>
                <label className={classes.navbar_brand}>
                  <img
                    className={classes.logo}
                    src={require('Assets/logo.png')}
                    alt="DashProphet"
                  />
                </label>
              </div>
              <div className={`col-md-6 text-md-right small ${classes.col_7}`}>
                <ul className={classes.navbar_nav}>
                  <li className="nav-item">
                    <a className="nav-link" href="/case_study">
                  Case Study
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://cruzstreet.com/pricing/">
                  Pricing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/learn">
                  Learn
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://cruzstreet.com/blog/">
                  Blog
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://dashprophet.com/about/">
                  About Us
                    </a>
                  </li>
                </ul>
                <ul className={`${classes.navbar_nav} ${classes.social_icon}`}>
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.facebook.com/CruzStreetLLC/">
                      <IconM size={32} icon="facebook" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://twitter.com/_cruzstreet">
                      <IconM size={32} icon="twitter" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.linkedin.com/company/cruzstreet/">
                      <IconM size={32} icon="instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={`container-fluid ${classes.footer_copyright}`}>
            <div className="row">
              <div className={`col-md-6 small ${classes.col_3}`}>
                <label className={classes.navbar_brand}>info@cruzstreet.com</label>
              </div>
              <div className={`col-md-6 text-md-right small ${classes.col_7}`}>
                <ul className={`${classes.navbar_nav} ${classes.privacy_policy}`}>
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.iubenda.com/privacy-policy/77140537">
                  Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.iubenda.com/terms-and-conditions/77140537">
                  Terms of Service
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.iubenda.com/privacy-policy/77140537/cookie-policy">
                  Cookie Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
        :
        <div className={`container-fluid ${classes.footer_copyright}`}>
          <div className="row">
            <div className={`col-md-6 small ${classes.col_3}`}>
              <label className={classes.navbar_brand}>info@cruzstreet.com</label>
            </div>
            <div className={`col-md-6 text-md-right small ${classes.col_7}`}>
              <ul className={`${classes.navbar_nav} ${classes.privacy_policy}`}>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.iubenda.com/privacy-policy/77140537">
                  Privacy Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.iubenda.com/terms-and-conditions/77140537">
                  Terms of Service
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.iubenda.com/privacy-policy/77140537/cookie-policy">
                  Cookie Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>}
    </footer>
  );
};

export default Footer;
