import React from 'react';
// import {
//     Home as HomeIcon
// } from 'react-feather';
// import Icon from '../Icon';
import useUser from 'Hooks/Account/useUser';
import { useHistory } from 'react-router-dom';

const Hint = (props) => {
  const { classes } = props;
  const { Signout } = useUser();
  const history = useHistory();

  const logout = async() => {
    await Signout();
    history.push('/');
  };

  return (
    <>
      <a className={classes.nav_hint_link} href="/support_tips">
        <div className={classes.hint_badge}>
          <label>1</label>
        </div>
        <span>Your First DashProphet Analysis</span>
      </a>
      <a className={classes.nav_hint_link} href="/support_tips">
        <div className={classes.hint_badge}>
          <label>2</label>
        </div>
        <span>Interactive Dashboard</span>
      </a>
      <a className={classes.nav_hint_link} href="/support_tips">
        <div className={classes.hint_badge}>
          <label>3</label>
        </div>
        <span>DashProphet Data Quality Testing Guide</span>
      </a>
      <div className={classes.logout_mobile}>
        <a className={classes.logout_item} href="#!" onClick={logout}>
          <div className={classes.logout_item_icon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-log-out"
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
              <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg>
          </div>
        Logout
        </a>
      </div>
    </>
  );
};

export default Hint;
