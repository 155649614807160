const getAssetPermissions = () => ({
  'parameters': {
    'region': 'us-east-1',
    'aws_account_id': `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
    'tenant_id_key': 0,
    'user_id_key': 0,
    'user_name': '',
    'user_email': '',
    'actions': 'list_permissions',
    'assets_to_apply_permissions': [],
    'dashboards_to_share_list': [],
    'inherit_asset_scope': 'no',
    'analyses_to_share_list': [],
    'templates_to_share_list': [],
    'datasets_to_share_list': [],
    'datasources_to_share_list': [],
    'share_with_user_namespace_list': [],
    'share_with_user_email_list': [],
    'share_with_user_role_list': [],
    'share_with_user_permission_type_list': [],
    'share_with_user_tenant_id_list': [],
    'share_with_user_cognito_role_list': [],
    'share_owned_by_user_email_list': [],
    'share_owned_by_user_namespace_list': [],
    'share_owned_by_user_tenant_id_list': [],
    'share_owned_by_user_cognito_role_list': [],
    'share_owned_by_project_id': 0,
    'share_all_project_assets': '',
    'share_with_all_project_users': '',
    'create_athena_datasets_payload': [],
    'refresh_athena_datasets_payload': [],
    'create_analysis_payload': {}
  }
});

export default getAssetPermissions;
