import React from 'react';
import { shape, string } from 'prop-types';
import { useTooltip } from '../../Context/Tooltip';
import { mergeClasses } from '../../classify';
import defaultClasses from './tooltipContainer.module.scss';

const ToastContainer = (props) => {
  const classes = mergeClasses(defaultClasses, props.classes);
  const [{ show, content, x, y }] = useTooltip();

  return (
    <div id="tooltip-root" className={classes.root}
      style={{ visibility: show ? 'visible' : 'hidden', top: `${y - 45}px`, left: `${x - 30}px` }}>
      {content}
    </div>
  );
};

ToastContainer.propTypes = {
  classes: shape({
    root: string
  })
};

export default ToastContainer;
