/* eslint-disable camelcase */
import axios from 'axios';
import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {  toast } from 'react-toastify';
import { useAppContext } from 'Context/app';
import parseJwt from 'Utilities/parseJwt';
import bindActionCreators from 'Utilities/bindActionCreators';
import * as asyncActions from 'Store/actions/auth/asyncActions';
import { refreshAccessToken, quickSightSession, getUser } from 'Services/authServices';
import useUser from 'Hooks/Account/useUser';
import { getDashprophetAssetPermissions } from 'Services/myDashboardService';
import getAssetPermissions from 'Constants/assetPermissions';

export const useMain = () => {
  const { token } = useSelector(({ auth }) => auth);
  const userParse = token && parseJwt(token);
  const history = useHistory();
  const [{ preload }, { closeDrawer }] = useAppContext();
  const dispatch = useDispatch();
  const { Signout } = bindActionCreators(asyncActions, dispatch);
  const { user } = useUser();
  const createdDate = new Date(user?.created_datetime);
  const permissions = getAssetPermissions();

  let timeInterval;

  const handleSignOut = useCallback(async() => {
    await Signout();
    history.push('/');
    closeDrawer();
    toast.error(`You have been Logged out. Please Sign in Again.`);
  }, [Signout, history, closeDrawer]);

  const migrateAssets = async() => {
    try {
      permissions.parameters.tenant_id_key = user.tenant_id_key;
      permissions.parameters.user_id_key = +user['custom:id_key'];
      permissions.parameters.user_name = user.user_name;
      permissions.parameters.user_email = user.email;

      permissions.parameters.actions=['migrate_asset_permissions'];
      permissions.parameters.assets_in_scope = ['dashboards', 'analyses', 'datasets', 'datasources', 'templates'];
      // permissions.parameters.assets_to_apply_permissions=[asset];

      permissions.parameters.share_with_user_namespace_list= [];
      permissions.parameters.share_with_user_email_list=[];
      permissions.parameters.share_with_user_role_list =[];
      permissions.parameters.share_with_user_permission_type_list=[];
      permissions.parameters.share_with_user_tenant_id_list=[];
      permissions.parameters.share_with_user_cognito_role_list= [];

      permissions.parameters.share_owned_by_user_email_list=[user.email];
      // permissions.parameters.share_owned_by_user_namespace_list=[`${user.tenant_id_key}`];
      permissions.parameters.share_owned_by_user_tenant_id_list=[user.tenant_id];
      // permissions.parameters.share_owned_by_user_cognito_role_list=[user.role];
      permissions.parameters.share_project_assets='no';
      permissions.parameters.share_with_all_project_users='no';
      permissions.parameters.share_with_all_project_users='no';
      permissions.parameters.analysis_payload={};

      const res = await getDashprophetAssetPermissions(permissions);
      console.log({res});
      toast.success(res.split(':')[0]);
    }  catch (err) {
      toast.error(err);
    }
  };
  useEffect(() => {
    axios.interceptors.response.use((res) => res, async(error) => {
      const originalReq = error.config;

      const isDashboard = error?.response.status === 400  && originalReq.url.includes('/dashboard-embed') &&  !error.response?.data.includes('ResourceNotFoundException');

      if (userParse && !originalReq._retry && error.response.status === 401 || isDashboard
        || (error.response.data.code === 'NotAuthorizedException' && originalReq.url !== '/auth')) {
        try {

          const token = await refreshAccessToken(userParse.email);

          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          originalReq._retry = true;
          originalReq.headers.Authorization = `Bearer ${token}`;
          if (isDashboard) {
            originalReq.params.IdToken = token;
          }

          return new Promise((resolve, reject) => {
            axios
              .request(originalReq)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error.response);
              });
          });
        } catch (err) {
          handleSignOut();
        }
      } else {
        return new Promise((resolve, reject) => {
          error.response.status === 500 && handleSignOut();
          reject(error);
        });
      }
    });
  }, []);


  useEffect(() => {
    // process.env.NODE_ENV === 'production' && user && loginSession();
    user && loginSession();

    return (
      user?.has_qs_profile && clearInterval(timeInterval)
    );

  }, [user]);

  const loginSession = () => {
    // if (user.role === 'TenantAdmin' && !user?.has_qs_profile) {
    if (!user?.has_qs_profile) {

      createdDate.setSeconds(createdDate.getSeconds() + 90);
      createdDate > new Date() && toast.warn('We are still setting up your account');

      timeInterval = setInterval(async() => {

        if (createdDate < new Date()) {

          clearInterval(timeInterval);

          quickSightSession()
            .then((res) => {
              toast.info(`Account settings Completed`);
              getUser().then(({user}) => localStorage.setItem('user', JSON.stringify({...userParse, ...user})));
              // quicksight();  // create open id connect call replaced with backend call
            }).then(() => {
              if (user?.migration_enabled) {
                migrateAssets();
              }
            }).catch((err) => console.log(err.data.errorMessage));

        }
      }, 1000);
    }
  };

  return { Signout, token, preload };
};

