import { createActions } from 'redux-actions';

const prefix = 'APP';
const actionTypes = [
  'TOGGLE_DRAWER',
  'SET_ONLINE',
  'SET_OFFLINE',
  'TOGGLE_SEARCH',
  'MARK_ERROR_HANDLED',
  'CHANGE_DEVICE',
  'IS_404',
  'TOGGLE_PRELOAD',
  'SERVICE_WORKER_MESSAGE',
  'GLOBAL_PROGRESS_BAR',
  'WEB_SOCKET',
  'SET_NOTIFICATION'
];

export default createActions(...actionTypes, { prefix });
