/* eslint-disable camelcase */
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from 'Context/app';

import useUser from 'Hooks/Account/useUser';
import {
  getNotifications,
  getUnreadNotifications,
  markAsRead,
  deleteNotification,
  archiveNotification
} from 'Services/notificationServices';

import { useDailogBox } from 'Context/DailogBox';
import { useDropdown } from '../useDropdown';

const useNotications = () => {
  const [{ socketConnectionVariable, notification }, { webSocket, setNotification }] = useAppContext();
  const [, { confirmDailogBox }] = useDailogBox();
  const { elementRef, expanded, setExpanded } = useDropdown();

  const { user } = useUser();

  const [toggleMode, setToggleMode] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if (!socketConnectionVariable) {
      const ws = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_END_POINT}?tenant_id_key=${user.tenant_id_key}&user_id_key=${+user['custom:id_key']}`
      );
      webSocket(ws);
    }
  }, []);

  if (socketConnectionVariable) {
    socketConnectionVariable.onmessage = (e) => {
      const dataItem = JSON.parse(e.data);
      const item = dataItem.Records[0].dynamodb.NewImage;

      data.unshift({
        created_datetime: item.created_datetime.S,
        notification_id: +item.id.N,
        message: item.message.S,
        is_read: false,
        recipient_type: item.recipient_type.N,
        notification_type: item.notification_type.N
      });
      setData(data);
      setNotification(notification+1);
    };
  }

  const handleClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  useEffect(() => {
    toggleMode ? getAllUnreadNotifications() : getAllNotifications();
  }, [toggleMode]);

  const handleToggle = () => {
    setToggleMode(!toggleMode);
  };

  const getAllUnreadNotifications = async() => {
    try {
      setIsLoading(true);
      const res = await getUnreadNotifications();
      const filteredData = res.filter((item) => item.status !== 4);
      setData(filteredData);
      setNotification(filteredData.length);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err.message);
    }
  };
  const getAllNotifications = async() => {
    try {
      setIsLoading(true);
      const res = await getNotifications();
      setData(res.filter((item) => item.status !== 4));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err.message);
    }
  };

  const handleDelete = async(item) => {
    const title = `Do you want to delete this notification`;
    confirmDailogBox({
      title,
      onConfirm: async() => {
        try {
          await deleteNotification({
            notification_id: item.notification_id
          });
          toggleMode ? getAllUnreadNotifications() : getAllNotifications();
        } catch (err) {
          console.log(err.message);
        }
      }
    });
  };

  const handleCopytoClipboard = (item) => {
    navigator.clipboard
      .writeText(item.message)
      .then(() => {
        // toast.success('Text copied to clipboard');
      })
      .catch((err) => {
        toast.error('Error in copying text: ', err);
      });
  };

  const handleMarkasRead = async(item) => {
    try {
      const params = {
        is_read: true,
        notification_id: item.notification_id,
        status: item.status
      };
      await markAsRead(params);
      setNotification(notification-1);
      toggleMode ? getAllUnreadNotifications() : getAllNotifications();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleMarkasUnread = async(item) => {
    try {
      const params = {
        is_read: false,
        notification_id: item.notification_id,
        status: item.status
      };
      await markAsRead(params);
      setNotification(notification+1);
      toggleMode ? getAllUnreadNotifications() : getAllNotifications();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleArchive = async(item) => {
    try {
      await archiveNotification({
        notification_id: item.notification_id
      });
      toggleMode ? getAllUnreadNotifications() : getAllNotifications();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return {
    data,
    toggleMode,
    isLoading,
    containerRef: elementRef,
    isOpen: expanded,
    handleArchive,
    handleMarkasUnread,
    handleMarkasRead,
    handleClick,
    handleToggle,
    handleDelete,
    handleCopytoClipboard
  };
};

export default useNotications;
