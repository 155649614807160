import React, { useEffect, useState } from 'react';
import { mergeClasses } from 'classify';
import { ReactComponent as AlertIcon } from 'Assets/icons/alert_content.svg';
import defaultClasses from './downgradeRoleTable.module.scss';
import { getRole } from 'Utilities/getRoleLable';
import { teamMembersByMemberId } from 'Services/myTeamService';


const DowngradeRoleTable = (props) => {
  const classes = mergeClasses(defaultClasses, props.classes);
  const [downgradeTableValues, setDowngradeTable] = useState();

  useEffect(() => {
    setDowngradeTable(null);
    props.message && getTeamMembers();
  }, [props.message]);

  const getTeamMembers = async() => {
    const params =  {
      operator: 'LT',
      role: props.message?.role
    };
    const response  = await teamMembersByMemberId(props.message?.id, params);
    setDowngradeTable(response);
  };

  return (
    <>
      <div className={`${classes.root}`}>
        <div className={`${classes.header_notice}`}>
          <AlertIcon className={`${classes.alert_icon}`} />
          <span className={`${classes.downgrade_notice}`}>You have chosen to downgrade user role. This might also downgrade the roles in different team.</span>
        </div>
        <table className={`${classes.downgrade_table}`}>
          <thead className={`${classes.downgrade_table_header}`}>
            <tr className={`${classes.downgrade_header_content}`}>
              <th>NAME</th>
              <th>CURRENT ROLE</th>
              <th>NEXT ROLE</th>
            </tr>
          </thead>
          {downgradeTableValues ? (
            <tbody className={`${classes.downgrade_table_body}`}>
              {downgradeTableValues?.map((data) => (
                <tr className={`${classes.row_content}`} key={data.team_id}>
                  <td className={`${classes.team_content} text-capitalize`}>
                    {data.team_name}
                  </td>
                  <td className={`${classes.name_content}`}>
                    {getRole(data.application_role)}
                  </td>
                  <td className={`${classes.name_content}`}>
                    {getRole(props.message.role)}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <div className={`${classes.loading_temp}`}>Loading...</div>
          )}
        </table>
        {downgradeTableValues?.length == 0 && <div className={`${classes.emptyData_temp}`}> No Teams Affected</div>}
        <div className={`${classes.btn_pos} mt-4 d-flex justify-content-end`}>
          <button type="button" className={`${classes.btn} ${classes.btn_primary}`} onClick={props.onCancel}>OK</button>
        </div>
      </div>
    </>
  );
};

export default DowngradeRoleTable;
