import { useCallback, useMemo } from 'react';
import { useDailogBoxContext } from './useDailogBoxContext';


export const useDailogBox = () => {
  const [state, dispatch] = useDailogBoxContext() || ['', ''];
  const confirmDailogBox = useCallback(
    ({
      message,
      title,
      icon,
      onConfirm,
      onCancel,
      firstButtonName,
      secondButtonName,
      htmlContent
    }) => {
      dispatch({
        type: 'confirm',
        payload: {
          message,
          title,
          icon,
          onConfirm,
          onCancel,
          firstButtonName,
          secondButtonName,
          htmlContent
        }
      });
    },
    [dispatch]
  );

  const successDailogBox = useCallback(
    ({
      message,
      title,
      icon,
      onConfirm
    }) => {
      dispatch({
        type: 'success',
        payload: {
          message,
          title,
          icon,
          onConfirm
        }
      });
    },
    [dispatch]
  );

  const failureDailogBox = useCallback(
    ({
      message,
      title,
      icon,
      onConfirm
    }) => {
      dispatch({
        type: 'success',
        payload: {
          message,
          title,
          icon,
          onConfirm
        }
      });
    },
    [dispatch]
  );


  const downgradeRole = useCallback(
    ({
      message,
      newRole,
      title,
      icon,
      onConfirm
    }) => {
      dispatch({
        type: 'downgrade_role',
        payload: {
          message,
          newRole,
          title,
          icon,
          onConfirm
        }
      });
    },
    [dispatch]
  );
  const closeDailog = useCallback(
    () => {
      dispatch({
        type: 'reset'
      });
    },
    [dispatch]
  );


  const api = useMemo(
    () => ({
      confirmDailogBox,
      successDailogBox,
      failureDailogBox,
      closeDailog,
      downgradeRole
    }),
    [confirmDailogBox, successDailogBox, failureDailogBox, closeDailog]
  );

  return [state, api];
};
