export const DELIMITER = '#,#';
export const getSearchFromStateReInit = (initialValue, filterKeys, filterState, filterItems) => {
  // preserve all existing params
  const nextParams = new URLSearchParams(initialValue);

  // iterate over available filters
  for (const key of filterKeys) {
    // remove any existing filter values
    nextParams.delete(key);
  }

  // iterate over the latest filter values
  for (const [group, items] of filterState) {
    //   const _filterItems = filterItems.get(group);
    for (const item of items) {
      const { title, value } = item || {};

      // append the new values
      nextParams.append(
        `${group}[filter]`,
        `${title}${DELIMITER}${value}`
      );
    }
  }

  // prepend `?` to the final string
  return `?${nextParams.toString()}`;
};

export const getSearchFromState = (initialValue, filterKeys, filterState) => {
  // preserve all existing params
  const nextParams = new URLSearchParams(initialValue);

  // iterate over available filters
  for (const key of filterKeys) {
    // remove any existing filter values
    nextParams.delete(key);
  }

  // iterate over the latest filter values
  for (const [group, items] of filterState) {
    for (const item of items) {
      const { title, value } = item || {};

      // append the new values
      nextParams.append(
        `${group}[filter]`,
        `${title}${DELIMITER}${value}`
      );
    }
  }

  // prepend `?` to the final string
  return `?${nextParams.toString()}`;
};

export const getStateFromSearch = (initialValue, filterKeys, filterItems) => {

  // preserve all existing params
  const params = new URLSearchParams(initialValue);
  const uniqueKeys = new Set(params.keys());
  const nextState = new Map();

  // iterate over existing param keys
  for (const key of uniqueKeys) {

    // if a key matches a known filter, add its items to the next state
    if (filterKeys.has(key) && key.endsWith('[filter]')) {
      // derive the group by slicing off `[filter]`
      const group = key.slice(0, -8);
      const items = new Set();
      const groupItemsByValue = new Map();


      // cache items by value to avoid inefficient lookups
      for (const item of filterItems.get(group)) {

        groupItemsByValue.set(item.value, item);
      }
      // map item values to items
      for (const value of params.getAll(key)) {
        const existingFilter = groupItemsByValue.get(
          getValueFromFilterString(value)
        );


        // console.log(value, getValueFromFilterString(value), groupItemsByValue, 'existingFilter');

        if (existingFilter) {
          items.add(existingFilter);
        } else {
          console.warn(
            `Existing filter ${value} not found in possible filters`
          );
        }
      }

      // add items to the next state, keyed by group
      nextState.set(group, items);
    }
  }

  return nextState;
};

/** GetFilterInput helpers below. */
const getValueFromFilterString = (keyValueString) =>
  // console.log(~~keyValueString.split(DELIMITER)[1]);
  // keyValueString.slice(keyValueString.length/2 + 1);
  keyValueString.split(DELIMITER)[1];

// Math.sign(~~keyValueString.split(DELIMITER)[1]) !== -1 ? ~~keyValueString.split(DELIMITER)[1] :
//   keyValueString.split(DELIMITER)[1]
//   || keyValueString.split(DELIMITER)[1];

export const getFiltersFromSearch = (initialValue) => {
  // preserve all existing params
  const params = new URLSearchParams(initialValue);
  const uniqueKeys = new Set(params.keys());
  const filters = new Map();

  // iterate over existing param keys
  for (const key of uniqueKeys) {
    // if a key matches a known filter, add its items to the next state
    if (key.endsWith('[filter]')) {
      // derive the group by slicing off `[filter]`
      const group = key.slice(0, -8);
      const items = new Set();

      // map item values to items
      for (const value of params.getAll(key)) {
        items.add(value);
      }

      // add items to the next state, keyed by group
      filters.set(group, items);
    }
  }

  return filters;
};


export const stripHtml = (html) => html.replace(/(<([^>]+)>)/gi, '');
