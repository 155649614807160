import React from 'react';
import { shape, string } from 'prop-types';
import { mergeClasses } from '../../classify';
import defaultClasses from './tooltip.module.scss';

const Tooltip = (props) => {
  const classes = mergeClasses(defaultClasses, props.classes);
  const { children } = props;
  return (
    <div id="tooltip-root" className={classes.root} style={props.style}>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  classes: shape({
    root: string
  })
};

export default Tooltip;
