import React from 'react';

import { mergeClasses } from 'classify';
import defaultClasses from './main.module.scss';
import { useScrollLock } from 'Hooks/useScrollLock';
import { useMain } from 'Hooks/App/useMain';
import AuthLayout from 'Layouts/AuthLayout';
import AdminLayout from 'Layouts/AdminLayout';
import { Modal } from 'Components/Modal';
import { fullPageLoadingIndicator } from 'Components/LoadingIndicator';

const Main = (props) => {
  const { children, isMasked } = props;
  const classes = mergeClasses(defaultClasses, props.classes);
  const { token, preload } = useMain();
  const rootClass = isMasked || preload ? classes.root_masked : classes.root;
  const pageClass = isMasked || preload ? classes.page_masked : classes.page;


  useScrollLock(isMasked);

  const Layout = token ? AdminLayout : AuthLayout;
  const _preload = preload ? (<Modal>
    <aside className={classes.preload}>
      {fullPageLoadingIndicator}
    </aside>
  </Modal>) : null;

  return (
    <main className={rootClass}>
      <div className={pageClass}>
        <Layout>{children}</Layout>
        {_preload}
      </div>
    </main>
  );
};

export default Main;
