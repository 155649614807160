import React, {useEffect} from 'react';
import axios from 'axios';
import { shape, string } from 'prop-types';
import { mergeClasses } from 'classify';
import CircularProgressBar from './circularProgressBar';
import { ChevronDown, ChevronUp, X, RefreshCw, XCircle} from 'react-feather';

import { useProgressBar } from 'Context/ProgressBar';
import Icon from 'Components/Icon';
// import { useAppContext } from 'Context/app';

import { uploadFile } from 'Services/dataSetServices';

import { ReactComponent as ConfirmIcon } from 'Assets/icons/tick_icon.svg';
import defaultClasses from './progressBarContainer.module.scss';


const DatasetName = (props) => {

  const { classes, files, datasetName, isExpanded } = props;
  const [{ isDetailed }, { handleDatasetToggle }] = useProgressBar();
  // const [, { toggleDrawer }] = useAppContext();
  // currently uploading files array
  const currentFiles = files.filter((file) => datasetName === file.dataset.dataset_name);
  let totalProgress = 0;
  currentFiles.map((item) => item.progress != 100 ? totalProgress = totalProgress+item.progress : null);
  const uploadCompleteFiles = currentFiles.filter((file) => file.progress === 100) ;
  const uploadFailed = currentFiles.filter((file) => file.status === 'failed');
  const length = currentFiles.length-uploadCompleteFiles.length-uploadFailed.length;
  // detect page refresh
  useEffect(() => {
    if (uploadCompleteFiles.length != currentFiles.length) {
      window.onbeforeunload = function() {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [length]);

  return (
    <>
      <div className={classes.dataset_label}>
        <label>
          {datasetName}
        </label>
        <div className={classes.dataset_buttons}>
          <label>
            {`${uploadCompleteFiles.length}/${currentFiles.length}`}
          </label>
          {/* {uploadCompleteFiles.length === currentFiles.length && !configuration.includes(datasetName) ?
            (
              <button type="button"
                className={`${classes.cog_btn}`}
                onClick={() => toggleDrawer('configure_dataset', currentFiles[0].dataset)}
              >
                <IconM size={12} icon="cog" color="#000000" />
              </button>
            ): null} */}
          <button type="button"
            className={`${classes.toggle_btn}`}
            onClick={() => handleDatasetToggle(datasetName)}
          >
            <Icon
              src={isDetailed.includes(datasetName) ? ChevronDown : ChevronUp}
            />
          </button>
        </div>

      </div>
      <div className={isExpanded && isDetailed.includes(datasetName) ? classes.progressBar_container : classes.not_progressBar_container}>
        {!!files.length && files.filter((item) => datasetName === item.dataset.dataset_name).map((file, key) =>
          (
            <div key={key} className={` pl-0 ${classes.progressBar_sub_container} ${file.status ==='failed' && classes.failedClass}`}>
              <ProgressBarItem classes={classes} {...file} />
            </div>)

        )}
      </div>
    </>
  );
};

const ProgressBarItem = (props) => {
  const [, { showProgressBar, cancelUpload, retryUpload }] = useProgressBar();

  const { progress, classes, name, size, cancelSource, status, url, fileToUpload, id } = props;

  const cancelFileUpload = async() => {
    cancelSource.cancel('user canceled');
  };

  const retryFileUpload = async() => {
    fileToUpload.cancelSource = axios.CancelToken.source();
    try {
      retryUpload({name: fileToUpload.name, cancelSource: fileToUpload.cancelSource});
      await uploadFile(url, {
        data: fileToUpload,
        showProgressBar,
        cancelUpload,
        id
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className={classes.label}>
        <label>{name}</label>
      </div>
      {progress !== 100 ?
        <div className={classes.progress_item}>
          {status === 'failed' ?
            <>
              <span className={classes.failed}>Failed</span>
              <button type="button" className={`btn ${classes.button}`} onClick={retryFileUpload}>
                <Icon src={RefreshCw} />
              </button>
            </>
            :
            <>
              <div className={classes.progressBar}>
                <CircularProgressBar
                  strokeWidth="3"
                  sqSize="25"
                  percentage={progress}
                />
              </div>
              <span>{`${progress}%`}</span>
              <button type="button" className={`ml-1 btn ${classes.button} ${classes.svg_close}`} onClick={cancelFileUpload}>
                <Icon src={XCircle} />
              </button>
            </>}
        </div>
        :
        <div className={classes.progress_item}>
          <span>{`${(size/1048576).toFixed(3)}Mb`}</span>
          <ConfirmIcon />
        </div>
      }
    </>
  );};

const ProgressBarContainer = (props) => {

  const classes = mergeClasses(defaultClasses, props.classes);
  const [{ files, isExpanded}, { hideProgressBar, handleToggle }] = useProgressBar();

  const checkFiles = [];

  for (let i = 0; i < files.length; i++) {
    if (files[i] && !checkFiles.includes(files[i])) {
      if (!checkFiles.length) {
        checkFiles.push(files[i].dataset.dataset_name);
      }
      else if (!checkFiles.includes(files[i].dataset.dataset_name)) {
        checkFiles.push(files[i].dataset.dataset_name);
      }
    }
  }

  let totalProgress = 0;
  files.map((item) => item.progress != 100 ? totalProgress = totalProgress+item.progress : null);

  const uploadCompleteFiles = files.filter((file) => file.progress === 100) ;
  const uploadFailed = files.filter((fileData) => fileData?.status === 'failed');
  const length = files.length-uploadCompleteFiles.length-uploadFailed.length;

  // detect page refresh
  useEffect(() => {
    if (uploadCompleteFiles.length != files.length) {
      window.onbeforeunload = function() {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [length]);

  return (
    <div id="progressbar-root" className={classes.root} style={{ display: (files.length > 0) ? 'block' : 'none' }} >
      {/* style={{ display: show ? 'block' : 'none' }}> */}
      <div className={classes.progress_label}>
        <label>
          {files.length != uploadCompleteFiles.length ? `Uploading` : 'Upload Completed'}
        </label>
        <button type="button" className={`btn ${classes.close_btn}`} onClick={() => hideProgressBar()} disabled={files.length != uploadFailed.length + uploadCompleteFiles.length}>
          <Icon src={X} />
        </button>
        <button type="button" className={`btn ${classes.toggle_btn}`} onClick={() => handleToggle(!isExpanded)} >
          <Icon src={isExpanded ? ChevronDown : ChevronUp} />
        </button>
      </div>

      <div className={isExpanded ? classes.datasetLabel_container : classes.not_datasetLabel_container}>
        {checkFiles.length && checkFiles.map((item, key) => (
          <div key={key} className={` pl-0 ${classes.dataSet_sub_container}`}>
            <DatasetName classes={classes} files={files} isExpanded={isExpanded} hideProgressBar={hideProgressBar} datasetName={item} />
          </div>
        )
        )}
      </div>
    </div>
  );
};

ProgressBarContainer.propTypes = {
  classes: shape({
    root: string
  })
};

export default ProgressBarContainer;
