import { useCallback, useMemo } from 'react';
import { useTooltipContext } from './useTooltipContext';

export const useTooltip = () => {
  const [state, dispatch] = useTooltipContext() || ['', ''];
  const hideTooltip = useCallback(
    () => {
      dispatch({
        type: 'hide',
        payload: {}
      });
    },
    [dispatch]
  );

  const showTooltip = useCallback(
    (e) => {
      const {
        clientX,
        clientY,
        currentTarget
      } = e;
      dispatch({
        type: 'show',
        payload: {
          content: currentTarget.dataset.content,
          x: clientX,
          y: clientY,
          show:true
        }
      });

    },
    [dispatch, hideTooltip]
  );

  /**
     * The API for managing toasts.
     * Use this API to add and remove toasts.
     *
     * @typedef API
     * @type Object
     */
  const api = useMemo(
    () => ({
      showTooltip,
      dispatch,
      hideTooltip
    }),
    [showTooltip, dispatch, hideTooltip]
  );

  return [state, api];
};
