import { Server, Model, Factory } from 'miragejs';
import {
  getUserById
} from './controllers/users';
import {
  getAllMyTeams,
  updateMyTeam,
  addMyTeam,
  deleteMyTeam
} from './controllers/myTeams';
import {
  getAllMyProjects,
  updateMyProject,
  addMyProject,
  deleteMyProject
} from './controllers/myProjects';

import {
  getAllMyDashboards
} from './controllers/myDashboards';
import { login } from './controllers/auth';
import { projects } from './seeds/myProjects';
import { teams } from './seeds/myTeams';
import { myDashboards } from './seeds/myDashboards';
import { usersSeeds } from './seeds/users';

export function makeServer({ environment = 'development' } = {}) {
  const server = new Server({
    environment,

    models: {
      myteam: Model,
      myDashboard: Model,
      myProject: Model,
      user: Model
    },

    factories: {
      myteam: Factory.extend({
        team_name(i) {
          return `myTeams ${i + 1}`;
        }
      }),
      user: Factory.extend({
        name(i) {
          return `name ${i + 1}`;
        }
      }),
      myProject: Factory.extend({
        text(i) {
          return `myProject ${i + 1}`;
        }
      }),
      myDashboard: Factory.extend({
        text(i) {
          return `myDashboard ${i + 1}`;
        }
      })
    },

    seeds(server) {

      teams.map((item) => {
        server.create('myteam', item);
      });
      projects.map((item) => {
        server.create('myProject', item);
      });
      myDashboards.map((item) => {
        server.create('myDashboard', item);
      });
      usersSeeds.map((item) => {
        server.create('user', item);
      });

    },

    routes() {
      this.namespace = 'api';
      this.timing = 750;

      this.post('/auth', login);

      this.get('/my_teams', getAllMyTeams);
      this.patch('/my_teams/:id', updateMyTeam);
      this.post('/my_teams', addMyTeam, { timing: 2000 });
      this.delete('/my_teams/:id', deleteMyTeam);

      this.get('/projects', getAllMyProjects);
      this.patch('/my_projects/:id', updateMyProject);
      this.post('/my_projects', addMyProject, { timing: 2000 });
      this.delete('/my_projects/:id', deleteMyProject);

      //users
      this.get('/user/:id', getUserById);

      //dashboards
      this.get('/my_dashboards', getAllMyDashboards);
    }
  });

  return server;
}
