import { handleActions } from 'redux-actions';

import actions from '../actions/app';
import { getCurrentDevice } from 'Utilities/deviceInfo';
export const name = 'app';
const activeDevice = getCurrentDevice();
export const initialState = {
  drawer: null,
  drawerProps: null,
  hasBeenOffline: !navigator.onLine,
  isOnline: navigator.onLine,
  overlay: false,
  searchOpen: true,
  query: '',
  pending: {},
  whichDeviceActive: activeDevice,
  is404: false,
  preload: false,
  serviceWorkerMessage: {
    type: null,
    payload: null
  },
  gloabalProgressBar: {},
  socketConnectionVariable: null,
  notification: 0
};

export const reducerMap = {
  [actions.toggleDrawer]: (state, { payload }) => ({
    ...state,
    drawer: payload.name,
    drawerProps: payload.data,
    overlay: !!payload.name
  }),
  [actions.toggleSearch]: (state) => ({
    ...state,
    searchOpen: !state.searchOpen
  }),
  [actions.setOnline]: (state) => ({
    ...state,
    isOnline: true
  }),
  [actions.setOffline]: (state) => ({
    ...state,
    isOnline: false,
    hasBeenOffline: true
  }),
  [actions.togglePreload]: (state) => ({
    ...state,
    preload: !state.preload
  }),
  [actions.changeDevice]: (state, { payload }) => ({
    ...state,
    whichDeviceActive: payload
  }),
  [actions.is404]: (state, { payload }) => ({
    ...state,
    is404: payload
  }),
  [actions.serviceWorkerMessage]: (state, { payload }) => ({
    ...state,
    serviceWorkerMessage: payload
  }),
  [actions.gloabalProgressBar]: (state, { payload }) => ({
    ...state,
    gloabalProgressBar: payload
  }),
  [actions.webSocket]: (state, { payload }) => ({
    ...state,
    socketConnectionVariable: payload
  }),
  [actions.setNotification]: (state, { payload }) => ({
    ...state,
    notification: payload
  })
};

export default handleActions(reducerMap, initialState);
