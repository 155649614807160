
export const projects = [
  {
    id: 'P1',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      },
      {
        userId: 'TM2',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM2',
      message: 'Hi Mark, Dashboard Ready!'
    }
  },
  {
    id: 'P2',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Aliquam, obcaecati quisquam. Aperiam, pariatur.',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      },
      {
        userId: 'TM2',
        teamId: 'someTeamId'
      },
      {
        userId: 'TM3',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM1',
      message: 'Hi Mark, please do something important'
    }
  },
  {
    id: 'P3',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Aliquam, obcaecati quisquam. Aperiam, pariatur.',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM3',
      message: 'Hi Mark, please do something important'
    }
  },
  {
    id: 'P4',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Aliquam, obcaecati quisquam. Aperiam, pariatur.',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM1',
      message: 'Hi Mark, please do something important'
    }
  },
  {
    id: 'P5',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Aliquam, obcaecati quisquam. Aperiam, pariatur.',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM1',
      message: 'Hi Mark, please do something important'
    }
  },
  {
    id: 'P6',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Aliquam, obcaecati quisquam. Aperiam, pariatur.',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM1',
      message: 'Hi Mark, please do something important'
    }
  },
  {
    id: 'P7',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Aliquam, obcaecati quisquam. Aperiam, pariatur.',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM1',
      message: 'Hi Mark, please do something important'
    }
  },
  {
    id: 'P8',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Aliquam, obcaecati quisquam. Aperiam, pariatur.',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM3',
      message: 'Hi Mark, please do something important'
    }
  },
  {
    id: 'P9',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Aliquam, obcaecati quisquam. Aperiam, pariatur.',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM1',
      message: 'Hi Mark, please do something important'
    }
  },
  {
    id: 'P10',
    entity_id: 'someEntityId',
    created_by_user: 'aakash@mailinator.com',
    project_name: 'Top Prospect Analysis',
    abbrvName: '(TP Q1 2020)',
    project_description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Aliquam, obcaecati quisquam. Aperiam, pariatur.',
    project_status: 'Business Undestanding',
    status: 'A',
    teamMembers: [
      {
        userId: 'TM1',
        teamId: 'someTeamId'
      }
    ],
    analyst: {
      userId: 'TM2',
      message: 'Hi Mark, please do something important'
    }
  }
];
