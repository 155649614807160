/* eslint-disable camelcase */
import axios from 'axios';

export const getMyTeams = async(params) => {
  try {
    const response = await axios.get('/teams', {params});
    return response.data;
  } catch (error) {

    console.log(error);
    throw {
      ...error.response.data,
      code: error.response.code
    };
  }
};

export const getAllTeams = async(params) => {
  try {
    const response = await axios.get('/teams_under_tenant', {params});
    return response.data;
  } catch (error) {

    console.log(error);
    throw {
      ...error.response.data,
      code: error.response.code
    };
  }
};

export const updateTeamMember = async(id, params) => {
  try {
    const response = await axios.put(`/team_member/${id}`, params);
    return response.data;
  } catch (error) {

    console.log(error);
    throw {
      ...error.response.data,
      code: error.response.code
    };
  }
};

export const getTeam = async(id) => {
  try {
    const res = await axios.get(`/team/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createTeam = async(team) => {
  try {
    const response = await axios.post(`/team`, team);
    return response.data;
  } catch (err) {

    console.log(err);
    throw err;
  }
};

export const getTeamMembers = async(id) => {
  try {
    const response = await axios.get(`/team_members/${id}`);
    return response.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

export const teamMembersByMemberId = async(id, params) => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.get(`/team_members_by_member/${id}`, {params});
    return response.data.data;
  } catch (err) {

    console.log(err);
    throw err;
  }
};

export const updateTeam = async(id, team) => {
  try {
    const response = await axios.put(`/team/${id}`, team);
    return response.data.data;
  } catch (err) {

    console.log(err);
    throw err;
  }
};

export const deleteTeam = async(id) => {
  try {
    const response = await axios.delete(`/team/${id}`);
    return response;
  } catch (err) {

    console.log(err);
    throw err;
  }
};

export const getTrialTeams = async(params) => {
  try {
    const response = await axios.get('/teams/99', {params});
    return response.data;
  } catch (error) {

    console.log(error);
    throw {
      ...error.response.data,
      code: error.response.code
    };
  }
};

export const getTeamsByUser = async(id) => {
  try {
    const response = await axios.get(`/teams/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getSharedTeams = async() => {
  try {
    const response = await axios.get(`/shared_teams`);
    return response.data.data;
  } catch (error) {
    throw error.response;
  }
};
