const getUserById = (schema, request) => {
  const data = schema.users.find(request.params.id);

  return data;
};


module.exports = {
  getUserById
};

