import React from 'react';

import IcomoonReact from 'icomoon-react';
import iconSet from 'Assets/selection.json';

const Icon = (props) => {
  const { color, size = '100%', icon, className = '', disabled  } = props;
  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={disabled ? '#C6CDD3' : color}
      size={size}
      icon={icon}
    />
  );
};

export default Icon;
