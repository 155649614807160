
const routes = {
  login: '/',
  signUp: '/sign_up',
  forgotPassword: '/forgot_password',
  resetPassword: '/reset_password/:email',
  resetPasswordLogin: '/reset_password/login/:email',
  myDashboard: '/my_dashboard',
  home: '/home',
  myProjects: '/my_projects',
  myAnalysts: '/my_analysts',
  myTeams: '/my_teams',
  myData: '/my_data',
  users: '/users',
  myDataId: '/my_data/:id',
  myDataClean: '/my_data/clean/:id',
  myDataPreview: '/my_data/preview/:id',
  messages: '/messages',
  supportTips: '/support_tips',
  caseStudy: '/case_study',
  termsOfService: '/terms_of_service',
  learn: '/learn',
  recentNotifications: '/recent_notifications',
  analyst: '/analyst/:id/:tenant_id_key',
  account: '/account/:id/:tenant_id',
  bio: '/edit_bio/:id/:tenant_id_key',
  analysis: '/my_dashboard/analysis',
  dataLake: '/my_data/data_lake',
  notifications: '/notifications',
  payment: '/payment',
  subscription: '/subscription',
  invoice: '/invoice',
  manageSubscription: '/manage_subscription',
  testDashboard: '/test_dashboard',
  settings: '/settings'
};

export default routes;
