/*eslint no-useless-catch: "error"*/
import axios from 'axios';
// change in dev with new url
// const DASHBOARD_URL = 'https://es5wy7lt3c.execute-api.us-east-1.amazonaws.com/prod/dashboard-embed';
const DASHBOARD_URL = '/dashboard-embed';

export const getMyDashboards = async(params) => {
  try {
    const res = await axios.get(DASHBOARD_URL, { params });
    return res.data;
  } catch (err) {
    throw {
      ...err.response.data,
      code: err.response.code
    };
  }

};

export const getDashprophetAssetPermissions = async(params) => {
  try {
    // const res = await axios.post('https://nxrtvwy4pd.execute-api.us-east-1.amazonaws.com/prod/dashprophetassetpermissions', params);
    const res = await axios.post('/dashprophetassetpermissions', params);
    return res.data;
  } catch (err) {
    throw err.response?.data;
  }
};

export const getProjectsDashboard = async() => {
  try {
    const res = await axios.get('/filter_data');
    return res.data.data;
  } catch (err) {
    throw err.response?.data;
  }
};

export const getProjectAnalysis = async() => {
  try {
    const res = await axios.get(`/project_analysis`);
    return res.data.data;
  } catch (err) {
    throw err.response?.data;
  }
};
