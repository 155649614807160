import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getSearchFromState } from 'Hooks/Dropdown/helpers';
import { useFilterState } from 'Hooks/Dropdown/useFilterState';


import { useDropdown } from '../useDropdown';

const initialValues = { search_query: '' };

export const useSearchBar = () => {
  const [valid, setValid] = useState(false);
  const [filterState] = useFilterState();

  const { elementRef, expanded, setExpanded } = useDropdown();
  const history = useHistory();
  const { push } = history;

  // expand or collapse on input change
  const handleChange = useCallback(
    (value) => {
      const hasValue = !!value;
      const isValid = hasValue && value.length > 2;

      setValid(isValid);
      setExpanded(hasValue);
    },
    [setExpanded, setValid]
  );

  // expand on focus
  const handleFocus = useCallback(() => {
    setExpanded(true);
  }, [setExpanded]);

  // navigate on submit
  const handleSubmit = useCallback(
    ({ search_query: searchQuery }) => {
      if (searchQuery != null && searchQuery.trim().length > 0) {


        const nextSearch = getSearchFromState(history.location.search, ['query'], filterState);

        push(`${history.location.pathname}${nextSearch}&query=${searchQuery}`);
        setExpanded(false);
      } else {
        const nextSearch = getSearchFromState(history.location.search, ['query'], filterState);

        history.push(`${history.location.pathname}${nextSearch}`);
      }

    },
    [push, setExpanded]
  );

  return {
    containerRef: elementRef,
    expanded,
    handleChange,
    handleFocus,
    handleSubmit,
    initialValues,
    setExpanded,
    setValid,
    valid
  };
};
