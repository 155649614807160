
export const teams = [
  {
    id: '1', team_name: 'Marketing', description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit...',
    team_members_count: 10, projects_count: 7, status: 'A'
  },
  {
    id: '2', team_name: 'Development', description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit...',
    team_members_count: 6, projects_count: 3, status: 'A'
  },
  {
    id: '3', team_name: 'Business Analysis', description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit...',
    team_members_count: 8, projects_count: 4, status: 'A'
  }
];
