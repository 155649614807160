/* eslint-disable camelcase */
export const snakeCase = (str) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');

export const toSnakeCase = (s) => {

  let new_str = '';
  for (let i=0; i < s.length; i++) {
    if (s[i].match(/[A-Z]/g)) {
      if (i > 0) {
        if (s[i-1].match(/[a-z]/g)) {new_str+='_';}
      }
      new_str+=s[i].toLowerCase();
    }
    else if (s[i].match(/[a-z]/g))
    {new_str+=s[i];}
    else if (s[i] == '_')
    {new_str+=s[i];}
    else if ([' ', '-'].includes(s[i]))
    {new_str+= '_';}
    else if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(s[i])) {
      if (i == 0) {
        new_str+='_';
        new_str+=s[i];
      }
      else
      {new_str+=s[i];}
    }
  }
  return new_str;

};
