/* eslint-disable */
const getOdbcActions = () => ({
  'job_master':
  {
    'user_id_key': 9999999999,
    'id': 0,
    'job_master_schema_version': 1,
    'job_master_job_owner_tenant_id_key': 1,
    'job_master_job_owner_tenant_company': '',
    'job_master_job_owner_email': '',
    'job_master_job_owner_user_name': '',
    'job_master_status': 1,
    'job_master_status_code': 0,
    'job_master_status_message': '',
    'job_master_debug_payload': {},
    'job_master_send_notification': 1,
    'job_master_notification_payload': {},
    'job_master_send_email': 1,
    'job_master_email_payload': {},
    'job_master_runtime_payload': {
      'runtime_schema_version': 1,
      'runtime_job_initiator': 2,
      'runtime_target_service': 1,
      'runtime_execution_step': 0,
      'runtime_max_folder_size': 3,
      'runtime_data_processed_exceeded_max': 2,
      'runtime_starting_tenant_total_object_storage': 0,
      'runtime_new_object_storage_for_job': 0,
      'runtime_max_object_storage_for_tenant': 0,
      'runtime_max_columns_for_tenant': 0,
      'runtime_max_rows_for_tenant': 0,
      'runtime_max_projects_for_tenant': 0,
      'runtime_create_dataset_iteration_count': 0,
      'runtime_max_create_dataset_iterations': 50,
      'runtime_update_job_tables': 2,
      'runtime_validate_sample_size_to_return': 10,
      'runtime_environment': process.env.REACT_APP_ENV
    },
    'job_master_action_payload': {
      'get_datasource_list': {
        'job_action_schema_version': 1,
        'job_action_process_this_action': 'no',
        'job_action_schedule_id': 0,
        'job_action_project_id': 0,
        'job_action_auto_generate_project': 1,
        'job_action_project_payload': {},
        'job_action_delete_destination_prior': 1,
        'job_action_source_use_connection_payload_override': 2,
        'job_action_source_connection_payload': {
          'connection_schema_version': 1,
          'connection_id': 0,
          'connection_aws_account_id': `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          'connection_tenant_id': 9999999999,
          'connection_tenant_company': 'ACC Consumer',
          'connection_user_id_key': 9999999999,
          'connection_user_email': 'admin@test.com',
          'connection_type': 7,
          'connection_data_location': 3,
          'connection_description': '',
          'connection_driver': 0,
          'connection_host': '',
          'connection_port': 0,
          'connection_database': '21_prod',
          'connection_database_schema': 'dbo',
          'connection_database_owner': '',
          'connection_external_key': '',
          'connection_username': '',
          'connection_password': '',
          'connection_secret_name': '',
          // 'connection_bucket': `${process.env.REACT_APP_ENV}-dashprophet-assets`,
          'connection_bucket': `dashprophet-assets`,
          'connection_subfolder': 'datalake'
        },
        'job_action_source_data_payload': {
          'source_schema_version': 1,
          'source_dataset_id': 0,
          'source_folder_size': 1,
          'source_crawl_method': 0,
          'source_origin_name': '',
          'source_name': '',
          'source_scope': 2,
          'source_data_retrieval_method': 1,
          'source_data_retrieval_scope': 1,
          'source_filetype': 2,
          'source_classification_type': 1,
          'source_compression_type': 0,
          'source_query_statement': '',
          'source_columns': [
            '*'
          ],
          'source_datatype_overrides': {},
          'source_primary_key_columns': [],
          'source_partition_key_columns': [],
          'source_filter_integer_values_only_columns': [],
          'source_chunksize_type': 1,
          'source_chunksize_integer': 0,
          'source_chunksize_boolean': true
        },
        'job_action_destination_use_connection_payload_override': 2,
        'job_action_destination_connection_payload': {
          'connection_schema_version': 1,
          'connection_id': 0,
          'connection_aws_account_id': `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          'connection_tenant_id': 9999999999,
          'connection_tenant_company': 'ACC Consumer',
          'connection_user_id_key': 9999999999,
          'connection_user_email': 'admin@test.com',
          'connection_type': 12,
          'connection_data_location': 2,
          'connection_description': '',
          'connection_driver': 0,
          'connection_host': '',
          'connection_port': 0,
          'connection_database': '',
          'connection_database_schema': '',
          'connection_database_owner': '',
          'connection_external_key': '',
          'connection_username': '',
          'connection_password': '',
          'connection_secret_name': '',
          // 'connection_bucket': `${process.env.REACT_APP_ENV}-dashprophet-assets`,
          'connection_bucket': `dashprophet-assets`,
          'connection_subfolder': 'datalake'
        },
        'job_action_destination_data_payload': {
          'destination_dataset_id': 0,
          'destination_name': 'Datasource_list',
          'destination_description': '',
          'destination_folder_size': 0,
          'destination_filetype': 2,
          'destination_scope': 2,
          'destination_compression_type': 0,
          'destination_classification_type': 1,
          'destination_columns': [
            '*'
          ],
          'destination_primary_key_columns': [],
          'destination_partition_key_columns': [],
          'destination_datatype_overrides': {},
          'destination_chunksize_type': 1,
          'destination_chunksize_integer': 0,
          'destination_chunksize_boolean': true,
          'destination_refresh_update_method': 0,
          'destination_dataset_cleansing_provider': 0,
          'destination_dataset_cleansing_recipe_id': 0
        }
      },
      'get_metadata': {
        'job_action_schema_version': 1,
        'job_action_process_this_action': 'no',
        'job_action_schedule_id': 0,
        'job_action_project_id': 0,
        'job_action_auto_generate_project': 1,
        'job_action_project_payload': {},
        'job_action_delete_destination_prior': 1,
        'job_action_source_use_connection_payload_override': 2,
        'job_action_source_connection_payload': {
          'connection_schema_version': 1,
          'connection_id': 0,
          'connection_aws_account_id': `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          'connection_tenant_id': 9999999999,
          'connection_tenant_company': 'ACC Consumer',
          'connection_user_id_key': 9999999999,
          'connection_user_email': 'admin@test.com',
          'connection_type': 7,
          'connection_data_location': 3,
          'connection_description': '',
          'connection_driver': 0,
          'connection_host': '',
          'connection_port': 0,
          'connection_database': '',
          'connection_database_schema': 'dbo',
          'connection_database_owner': '',
          'connection_external_key': '',
          'connection_username': '',
          'connection_password': '',
          'connection_secret_name': '',
          // 'connection_bucket': `${process.env.REACT_APP_ENV}-dashprophet-assets`,
          'connection_bucket': `dashprophet-assets`,
          'connection_subfolder': 'datalake'
        },
        'job_action_source_data_payload': {
          'source_schema_version': 1,
          'source_dataset_id': 0,
          'source_folder_size': 1,
          'source_crawl_method': 0,
          'source_origin_name': 'Loan',
          'source_name': 'Loan',
          'source_data_retrieval_method': 1,
          'source_data_retrieval_scope': 2,
          'source_scope': 2,
          'source_filetype': 2,
          'source_classification_type': 1,
          'source_compression_type': 0,
          'source_query_statement': '',
          'source_columns': [
            'OriginationProgramID'
          ],
          'source_datatype_overrides': {},
          'source_primary_key_columns': [],
          'source_partition_key_columns': [],
          'source_filter_integer_values_only_columns': [],
          'source_chunksize_type': 1,
          'source_chunksize_integer': 0,
          'source_chunksize_boolean': true
        }
      },
      'validate_data_connection': {
        'job_action_schema_version': 1,
        'job_action_process_this_action': 'no',
        'job_action_schedule_id': 0,
        'job_action_project_id': 0,
        'job_action_auto_generate_project': 1,
        'job_action_project_payload': {},
        'job_action_delete_destination_prior': 1,
        'job_action_source_use_connection_payload_override': 2,
        'job_action_source_connection_payload': {
          'connection_schema_version': 1,
          'connection_id': 0,
          'connection_aws_account_id': `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          'connection_tenant_id': 9999999999,
          'connection_tenant_company': '',
          'connection_user_id_key': 9999999999,
          'connection_user_email': '',
          'connection_type': 12,
          'connection_data_location': 2,
          'connection_description': '',
          'connection_driver': 0,
          'connection_host': '',
          'connection_port': 0,
          'connection_database': '',
          'connection_database_schema': '',
          'connection_database_owner': '',
          'connection_external_key': '',
          'connection_username': '',
          'connection_password': '',
          'connection_secret_name': '1301520737-14946781806-0000000001',
          // 'connection_bucket': `${process.env.REACT_APP_ENV}-dashprophet-assets`,
          'connection_bucket': `dashprophet-assets`,
          'connection_subfolder': 'datalake'
        },
        'job_action_source_data_payload': {
          'source_schema_version': 1,
          'source_dataset_id': 0,
          'source_folder_size': 1,
          'source_crawl_method': 0,
          'source_origin_name': 'Loan',
          'source_name': 'loan',
          'source_scope': 2,
          'source_data_retrieval_method': 1,
          'source_data_retrieval_scope': 2,
          'source_filetype': 2,
          'source_classification_type': 4,
          'source_compression_type': 0,
          'source_query_statement': '',
          'source_columns': [
            '*'
          ],
          'source_datatype_overrides': {},
          'source_primary_key_columns': [],
          'source_partition_key_columns': [],
          'source_filter_integer_values_only_columns': [],
          'source_chunksize_type': 1,
          'source_chunksize_integer': 0,
          'source_chunksize_boolean': true
        }
      },
      'create_dataset': {
        'job_action_schema_version': 1,
        'job_action_process_this_action': 'no',
        'job_action_schedule_id': 0,
        'job_action_project_id': 0,
        'job_action_auto_generate_project': 1,
        'job_action_project_payload': {},
        'job_action_delete_destination_prior': 1,
        'job_action_source_use_connection_payload_override': 2,
        'job_action_source_connection_payload': {
          'connection_schema_version': 1,
          'connection_id': 0,
          'connection_aws_account_id': `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          'connection_tenant_id': 9999999999,
          'connection_tenant_company': 'ACC Consumer',
          'connection_user_id_key': 9999999999,
          'connection_user_email': 'admin@test.com',
          'connection_type': 7,
          'connection_data_location': 3,
          'connection_description': '',
          'connection_driver': 0,
          'connection_host': '',
          'connection_port': 0,
          'connection_database': '',
          'connection_database_schema': 'dbo',
          'connection_database_owner': '',
          'connection_external_key': '',
          'connection_username': '',
          'connection_password': '',
          'connection_secret_name': '',
          // 'connection_bucket': `${process.env.REACT_APP_ENV}-dashprophet-assets`,
          'connection_bucket': `dashprophet-assets`,
          'connection_subfolder': 'datalake'
        },
        'job_action_source_data_payload': {
          'source_dataset_id': 1,
          'source_folder_size': 1,
          'source_crawl_method': 0,
          'source_origin_name': 'Loan',
          'source_name': 'Loan',
          'source_scope': 4,
          'source_data_retrieval_method': 1,
          'source_data_retrieval_scope': 2,
          'source_filetype': 2,
          'source_classification_type': 1,
          'source_compression_type': 0,
          'source_query_statement': '',
          'source_columns': [],
          'source_datatype_overrides': {},
          'source_primary_key_columns': [],
          'source_partition_key_columns': [],
          'source_filter_integer_values_only_columns': [],
          'source_chunksize_type': 1,
          'source_chunksize_integer': 0,
          'source_chunksize_boolean': true
        },
        'job_action_destination_use_connection_payload_override': 2,
        'job_action_destination_connection_payload': {
          'connection_schema_version': 1,
          'connection_id': 0,
          'connection_aws_account_id': `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          'connection_tenant_id': 0,
          'connection_tenant_company': '',
          'connection_user_id_key': 0,
          'connection_user_email': 'admin@test.com',
          'connection_type': 4,
          'connection_data_location': 2,
          'connection_description': '',
          'connection_driver': 0,
          'connection_host': '',
          'connection_port': 0,
          'connection_database': '',
          'connection_database_schema': 'dbo',
          'connection_database_owner': '',
          'connection_external_key': '',
          'connection_username': '',
          'connection_password': '',
          'connection_secret_name': '',
          // 'connection_bucket': `${process.env.REACT_APP_ENV}-dashprophet-assets`,
          'connection_bucket': `dashprophet-assets`,
          'connection_subfolder': 'datalake'
        },
        'job_action_destination_data_payload': {
          'destination_dataset_id': 0,
          'destination_folder_size': 0,
          'destination_name': 'neoito_test_',
          'destination_description': 'ACC Loan dataset',
          'destination_filetype': 2,
          'destination_scope': 2,
          'destination_compression_type': 0,
          'destination_classification_type': 4,
          'destination_columns': [
            '*'
          ],
          'destination_primary_key_columns': [],
          'destination_partition_key_columns': [],
          'destination_datatype_overrides': {},
          'destination_chunksize_type': 1,
          'destination_chunksize_integer': 0,
          'destination_chunksize_boolean': true,
          'destination_refresh_update_method': 2,
          'destination_dataset_cleansing_provider': 0,
          'destination_dataset_cleansing_recipe_id': 0
        },
        "job_action_use_cleansing_payload_override": 2,
        "job_action_recipe_id": 0,
        "job_action_cleansing_payload": [
            {
            "Action": {
                "Operation": "RENAME",
                "Parameters": {
                    "sourceColumn": "",
                    "targetColumn": ""
                }
            } 
            }
        ]
      },
      'create_dataset_from_join': {
        'job_action_schema_version': 1,
        'job_action_process_this_action': 'no',
        'job_action_schedule_id': 0,
        'job_action_project_id': 0,
        'job_action_auto_generate_project': 1,
        'job_action_project_payload': {},
        'job_action_delete_destination_prior': 1,
        'job_action_source_left_use_connection_payload_override': 1,
        'job_action_source_left_connection_payload': {
          "connection_schema_version": 1,
          "connection_id": 1,
          "connection_user_id_key": 1896448581
        },
        'job_action_source_left_data_payload': {
          'source_dataset_id': 0,
          'source_folder_size': 0,
          'source_crawl_method': 0,
          'source_origin_name': 'LoanClient',
          'source_name': 'LoanClient',
          'source_scope': 2,
          'source_data_retrieval_method': 1,
          'source_data_retrieval_scope': 2,
          'source_filetype': 2,
          'source_classification_type': 4,
          'source_compression_type': 0,
          'source_query_statement': '',
          'source_columns': [
            'ClientID',
            'LoanClientID',
            'LoanID',
            'EntityID',
            'ClientTypeRefID',
            'RelationshipTypeRefID'
          ],
          'source_datatype_overrides': {},
          'source_primary_key_columns': [],
          'source_partition_key_columns': [],
          'source_filter_integer_values_only_columns': [],
          'source_chunksize_type': 1,
          'source_chunksize_integer': 0,
          'source_chunksize_boolean': true
        },
        'job_action_source_right_use_connection_payload_override': 1,
        'job_action_source_right_connection_payload': {
          "connection_schema_version": 1,
          "connection_id": 1,
          "connection_user_id_key": 1896448581
        },
        'job_action_source_right_data_payload': {
          'source_dataset_id': 0,
          'source_folder_size': 0,
          'source_crawl_method': 0,
          'source_origin_name': '',
          'source_name': '',
          'source_scope': 2,
          'source_data_retrieval_method': 1,
          'source_data_retrieval_scope': 2,
          'source_filetype': 2,
          'source_classification_type': 4,
          'source_compression_type': 0,
          'source_query_statement': '',
          'source_columns': [
            'LoanID',
            'AccountNumber',
            'OriginalLoanID',
            'PartnerAccountNumber',
            'SourceFinancialSystemRefID',
            'CompanyID',
            'BranchID',
            'LoanProgramID',
            'OriginationProgramID',
            'ProductID',
            'ProductJurisdictionID',
            'ClientID',
            'IsNotEligibleForBorrowerRefunds',
            'IsPaymentProcessingOnHold',
            'IsDoNotCall',
            'FIManager',
            'PaymentHistoryProfile',
            'IsExcludeFromCreditReporting',
            'CreatedDate',
            'CreatedByUserID',
            'LastModifiedDate',
            'LastModifiedByUserID',
            'GrossBalance',
            'IsPTO'
          ],
          'source_datatype_overrides': {},
          'source_primary_key_columns': [],
          'source_partition_key_columns': [],
          'source_filter_integer_values_only_columns': [],
          'source_chunksize_type': 1,
          'source_chunksize_integer': 0,
          'source_chunksize_boolean': true
        },
        'job_action_join_how': 'left',
        'job_action_destination_use_connection_payload_override': 2,
        'job_action_destination_connection_payload': {
          "connection_schema_version": 1,
          "connection_id": 0,
          "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          "connection_tenant_id": 1,
          "connection_tenant_company": "Cruz Street",
          "connection_user_id_key": 961211902,
          "connection_user_email": "admin@cruzstreet.com",
          "connection_type": 4,
          "connection_data_location": 2,
          "connection_driver": 0,
          "connection_host": "",
          "connection_port": 0,
          "connection_database": "1",
          "connection_database_schema": "",
          "connection_database_owner": "",
          "connection_external_key": "",
          "connection_username": "",
          "connection_password": "",
          "connection_secret_name": "",
          "connection_bucket": "dashprophet-assets",
          "connection_subfolder": "datalake"
        },
        'job_action_destination_data_payload': {
          'destination_dataset_id': 0,
          'destination_name': 'loan_joined',
          'destination_description': 'ACC Loan joined dataset',
          'destination_folder_size': 0,
          'destination_filetype': 2,
          'destination_scope': 2,
          'destination_compression_type': 0,
          'destination_classification_type': 4,
          'destination_columns': [
            '*'
          ],
          'destination_primary_key_columns': [],
          'destination_partition_key_columns': [],
          'destination_datatype_overrides': {},
          'destination_chunksize_type': 1,
          'destination_chunksize_integer': 0,
          'destination_chunksize_boolean': true,
          'destination_refresh_update_method': 2,
          'destination_dataset_cleansing_provider': 0,
          'destination_dataset_cleansing_recipe_id': 0
        },
        "job_action_recipe_id": 0,
      },
      "create_dashboard": {
        "job_action_schema_version": 1,
        "job_action_process_this_action": "no",
        "job_action_auto_generate_project": 1,
        "job_action_project_payload": {
            "user_id_key": 0,
            "id": 0,
            "project_name": "",
            "project_description": "",
            "project_use_case_id": "",
            "project_requested_completion_datetime": "",
            "project_alias": "",
            "project_special_intructions": "",
            "project_status": "",
            "project_last_viewed_datetime": ""
        },
        "job_action_project_member_payload": {
            "member_id": 0,
            "member_type": ""
        },
        "job_action_source_dashboard_dataset_payloads": {
            "job_action_source_dashboard_dataset_payload":
            [
                {
                    "job_action_source_use_connection_payload_override": 1,
                    "job_action_source_connection_payload": {
                        "connection_schema_version": 1,
                        "connection_id": 2,
                        "connection_user_id_key": 1896448581
                    },
                    "job_action_source_data_payload": {
                    "source_schema_version": 1,
                    "source_dataset_id": 0,
                    "source_folder_size": 1,
                    "source_crawl_method": 0,
                    "source_origin_name": "Loan",
                    "source_name": "loan",
                    "source_scope": 2,
                    "source_data_retrieval_method": 1,
                    "source_data_retrieval_scope": 2,
                    "source_filetype": 2,
                    "source_classification_type": 4,
                    "source_compression_type": 0,
                    "source_query_statement": "",
                    "source_columns": [
                        "*"
                    ],
                    "source_datatype_overrides": {},
                    "source_primary_key_columns": [],
                    "source_partition_key_columns": [],
                    "source_filter_integer_values_only_columns": [],
                    "source_chunksize_type": 1,
                    "source_chunksize_integer": 0,
                    "source_chunksize_boolean": true
                    }
                }
            ]
        }
      },
      "refresh_dataset": {
        "job_action_schema_version": 1,
        "job_action_process_this_action": "no",
        "job_action_schedule_id": 0,
        "job_action_project_id": 0,
        "job_action_auto_generate_project": 1,
        "job_action_project_payload": {},
        "job_action_delete_destination_prior": 1,
        "job_action_source_use_connection_payload_override": 1,
        "job_action_source_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 1,
          "connection_user_id_key": 1896448581
        },
        "job_action_source_data_payload": {
          "source_dataset_id": 3027615849,
          "source_folder_size": 1,
          "source_crawl_method": 0,
          "source_origin_name": "Dealer_Application",
          "source_name": "da_loan_joined",
          "source_scope": 2,
          "source_data_retrieval_method": 2,
          "source_data_retrieval_scope": 1,
          "source_filetype": 2,
          "source_classification_type": 1,
          "source_compression_type": 0,
          "source_query_statement": "SELECT Convert(DATE,da.CreatedDate) as[Date], COUNT(*) as [App Submitted Count], COUNT(case da.StatusId WHEN 4 then 1 else null end) as [App Accepted Count], Count(case da.StatusId WHEN 5 THEN 1 else null end) as [App Denied Count], COUNT(CASE dbo.GetLoanStatus(l.LoanID, da.CreatedDate) WHEN 601 THEN 1 ELSE NULL END) AS [Cars sold] FROM Dealer_Application da INNER JOIN Dealer_Application_Status das ON das.StatusId = da.StatusId INNER JOIN Loan l ON l.LoanID = da.LoanId GROUP BY Convert(DATE,da.CreatedDate)",
          "source_columns": [
            "*"
          ],
          "source_datatype_overrides": {},
          "source_primary_key_columns": [],
          "source_partition_key_columns": [],
          "source_filter_integer_values_only_columns": [],
          "source_chunksize_type": 1,
          "source_chunksize_integer": 0,
          "source_chunksize_boolean": true
        },
        "job_action_destination_use_connection_payload_override": 1,
        "job_action_destination_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 1,
          "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          "connection_tenant_id": 1301520737,
          "connection_tenant_company": "ACC Consumer",
          "connection_user_id_key": 1896448581,
          "connection_user_email": "admin@accconsumer.com",
          "connection_type": 4,
          "connection_data_location": 2,
          "connection_driver": 1,
          "connection_host": "",
          "connection_port": 0,
          "connection_database": "1301520737",
          "connection_database_schema": "",
          "connection_database_owner": "",
          "connection_external_key": "",
          "connection_username": "",
          "connection_password": "",
          "connection_secret_name": "",
          "connection_bucket": "dashprophet-assets",
          "connection_subfolder": "datalake",
          "connection_import_mode": "SPICE"
        },
        "job_action_destination_data_payload": {
          "destination_dataset_id": 3027615849,
          "destination_refresh_id_key": 1274065839, 
          "destination_folder_size": 0,
          "destination_name": "da_loan_joined",
          "destination_description": "ACC Loan dataset created using join statement",
          "destination_filetype": 2,
          "destination_scope": 2,
          "destination_compression_type": 0,
          "destination_classification_type": 6,
          "destination_columns": [
            "*"
          ],
          "destination_primary_key_columns": [],
          "destination_partition_key_columns": [],
          "destination_datatype_overrides": {},
          "destination_chunksize_type": 1,
          "destination_chunksize_integer": 0,
          "destination_chunksize_boolean": true,
          "destination_refresh_update_method": 2,
          "destination_dataset_cleansing_provider": 0,
          "destination_dataset_cleansing_recipe_id": 0,
          "destination_allowed_to_manage": [],
          "destination_allowed_to_use": []
        }
      },
      "refresh_dataset_from_join": {
        'job_action_schema_version': 1,
        'job_action_process_this_action': 'no',
        'job_action_schedule_id': 0,
        'job_action_project_id': 0,
        'job_action_auto_generate_project': 1,
        'job_action_project_payload': {},
        'job_action_delete_destination_prior': 1,
        'job_action_source_left_use_connection_payload_override': 1,
        'job_action_source_left_connection_payload': {
          "connection_schema_version": 1,
          "connection_id": 1,
          "connection_user_id_key": 1896448581
        },
        'job_action_source_left_data_payload': {
          'source_dataset_id': 0,
          'source_folder_size': 0,
          'source_crawl_method': 0,
          'source_origin_name': 'LoanClient',
          'source_name': 'LoanClient',
          'source_scope': 2,
          'source_data_retrieval_method': 1,
          'source_data_retrieval_scope': 2,
          'source_filetype': 2,
          'source_classification_type': 4,
          'source_compression_type': 0,
          'source_query_statement': '',
          "source_columns": [
            "*"
          ],
          'source_datatype_overrides': {},
          'source_primary_key_columns': [],
          'source_partition_key_columns': [],
          'source_filter_integer_values_only_columns': [],
          'source_chunksize_type': 1,
          'source_chunksize_integer': 0,
          'source_chunksize_boolean': true
        },
        'job_action_source_right_use_connection_payload_override': 1,
        'job_action_source_right_connection_payload': {
          "connection_schema_version": 1,
          "connection_id": 1,
          "connection_user_id_key": 1896448581
        },
        'job_action_source_right_data_payload': {
          'source_dataset_id': 0,
          'source_folder_size': 0,
          'source_crawl_method': 0,
          'source_origin_name': '',
          'source_name': '',
          'source_scope': 2,
          'source_data_retrieval_method': 1,
          'source_data_retrieval_scope': 2,
          'source_filetype': 2,
          'source_classification_type': 4,
          'source_compression_type': 0,
          'source_query_statement': '',
          "source_columns": [
            "*"
          ],
          'source_datatype_overrides': {},
          'source_primary_key_columns': [],
          'source_partition_key_columns': [],
          'source_filter_integer_values_only_columns': [],
          'source_chunksize_type': 1,
          'source_chunksize_integer': 0,
          'source_chunksize_boolean': true
        },
        'job_action_join_how': 'left',
        'job_action_join_keys': [
          'ClientID'
        ],
        'job_action_destination_use_connection_payload_override': 2,
        'job_action_destination_connection_payload': {
          "connection_schema_version": 1,
          "connection_id": 0,
          "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          "connection_tenant_id": 1,
          "connection_tenant_company": "Cruz Street",
          "connection_user_id_key": 961211902,
          "connection_user_email": "admin@cruzstreet.com",
          "connection_type": 4,
          "connection_data_location": 2,
          "connection_driver": 0,
          "connection_host": "",
          "connection_port": 0,
          "connection_database": "1",
          "connection_database_schema": "",
          "connection_database_owner": "",
          "connection_external_key": "",
          "connection_username": "",
          "connection_password": "",
          "connection_secret_name": "",
          "connection_bucket": "dashprophet-assets",
          "connection_subfolder": "datalake"
        },
        'job_action_destination_data_payload': {
          'destination_dataset_id': 0,
          'destination_name': 'loan_joined',
          'destination_description': 'ACC Loan joined dataset',
          'destination_folder_size': 0,
          'destination_filetype': 2,
          'destination_scope': 2,
          'destination_compression_type': 0,
          'destination_classification_type': 4,
          'destination_columns': [
            '*'
          ],
          'destination_primary_key_columns': [],
          'destination_partition_key_columns': [],
          'destination_datatype_overrides': {},
          'destination_chunksize_type': 1,
          'destination_chunksize_integer': 0,
          'destination_chunksize_boolean': true,
          'destination_refresh_update_method': 2,
          'destination_dataset_cleansing_provider': 0,
          'destination_dataset_cleansing_recipe_id': 0
        },
        "job_action_use_cleansing_payload_override": 2,
        "job_action_recipe_id": 0,
        "job_action_cleansing_payload": [
            {
            "Action": {
                "Operation": "RENAME",
                "Parameters": {
                    "sourceColumn": "",
                    "targetColumn": ""
                }
            } 
            }
        ]
      },
      "create_analysis": {
        "job_action_schema_version": 1,
        "job_action_process_this_action": "no",
        "job_action_schedule_id": 0,
        "job_action_project_id": 0,
        "job_action_auto_generate_project": 1,
        "job_action_project_payload": {},
        "job_action_delete_destination_prior": 1,
        "job_action_source_use_connection_payload_override": 1,
        "job_action_source_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 0,
          "connection_user_id_key": 1229875265,
      },
        "job_action_source_data_payload": {
          "source_dataset_id": 5192764803,
          "source_folder_size": 1,
          "source_crawl_method": 0,
          "source_origin_name": "Dealer_Application",
          "source_name": "Dealer_Application",
          "source_scope": 1,
          "source_data_retrieval_method": 1,
          "source_data_retrieval_scope": 1,
          "source_filetype": 2,
          "source_classification_type": 7,
          "source_compression_type": 0,
          "source_query_statement": "",
          "source_columns": [
            "*"
          ],
          "source_datatype_overrides": {},
          "source_primary_key_columns": [],
          "source_partition_key_columns": [],
          "source_filter_integer_values_only_columns": [],
          "source_chunksize_type": 1,
          "source_chunksize_integer": 0,
          "source_chunksize_boolean": true
        },
        "job_action_destination_use_connection_payload_override": 2,
        "job_action_destination_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 0,
          "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          "connection_tenant_id": 1,
          "connection_tenant_company": "Cruz Street",
          "connection_user_id_key": 1244041984,
          "connection_user_email": "ciro@cruzstreet.com",
          "connection_type": 2,
          "connection_data_location": 2,
          "connection_driver": 0,
          "connection_host": "",
          "connection_port": 0,
          "connection_database": "1",
          "connection_database_schema": "",
          "connection_database_owner": "",
          "connection_external_key": "",
          "connection_username": "",
          "connection_password": "",
          "connection_secret_name": "",
          "connection_bucket": "dashprophet-assets",
          "connection_subfolder": "datalake",
          "connection_import_mode": "SPICE"
        },
        "job_action_destination_data_payload": {
          "destination_dataset_id": 0,
          "destination_folder_size": 0,
          "destination_name": "dealer_application",
          "destination_description": "ACC Loan dataset created using join statement",
          "destination_filetype": 2,
          "destination_scope": 1,
          "destination_compression_type": 0,
          "destination_classification_type": 7,
          "destination_columns": [
            "*"
          ],
          "destination_primary_key_columns": [],
          "destination_partition_key_columns": [],
          "destination_datatype_overrides": {},
          "destination_chunksize_type": 1,
          "destination_chunksize_integer": 0,
          "destination_chunksize_boolean": true,
          "destination_refresh_update_method": 2,
          "destination_dataset_cleansing_provider": 0,
          "destination_dataset_cleansing_recipe_id": 0,
          "destination_allowed_to_manage": [],
          "destination_allowed_to_use": []
        },
        "job_action_use_cleansing_payload_override": 2,
        "job_action_recipe_id": 0,
        "job_action_cleansing_payload": [
            {
            "Action": {
                "Operation": "RENAME",
                "Parameters": {
                    "sourceColumn": "",
                    "targetColumn": ""
                }
            } 
            }
        ]
      },
      "preview_dataset_from_join": {
        "job_action_schema_version": 1,
        "job_action_process_this_action": "no",
        "job_action_schedule_id": 0,
        "job_action_delete_destination_prior": 1,
        "job_action_source_left_use_connection_payload_override": 1,
        "job_action_source_left_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 1,
          "connection_user_id_key": 279967522
        },
        "job_action_source_left_data_payload": {
          "source_dataset_id": 0,
          "source_folder_size": 0,
          "source_crawl_method": 0,
          "source_origin_name": "LoanClient",
          "source_name": "LoanClient",
          "source_scope": 1,
          "source_data_retrieval_method": 1,
          "source_data_retrieval_scope": 2,
          "source_filetype": 2,
          "source_classification_type": 1,
          "source_compression_type": 0,
          "source_query_statement": "",
          "source_columns": [
            "ClientID",
            "LoanClientID",
            "LoanID",
            "EntityID",
            "ClientTypeRefID",
            "RelationshipTypeRefID"
          ],
          "source_datatype_overrides": {},
          "source_primary_key_columns": [],
          "source_partition_key_columns": [],
          "source_filter_integer_values_only_columns": [],
          "source_chunksize_type": 1,
          "source_chunksize_integer": 0,
          "source_chunksize_boolean": true
        },
        "job_action_source_right_use_connection_payload_override": 1,
        "job_action_source_right_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 1,
          "connection_user_id_key": 279967522
        },
        "job_action_source_right_data_payload": {
          "source_dataset_id": 0,
          "source_folder_size": 0,
          "source_crawl_method": 0,
          "source_origin_name": "Loan",
          "source_name": "Loan",
          "source_scope": 1,
          "source_data_retrieval_method": 1,
          "source_data_retrieval_scope": 2,
          "source_filetype": 2,
          "source_classification_type": 1,
          "source_compression_type": 0,
          "source_query_statement": "",
          "source_columns": [
            "LoanID",
            "AccountNumber",
            "OriginalLoanID",
            "PartnerAccountNumber",
            "SourceFinancialSystemRefID",
            "CompanyID",
            "BranchID",
            "LoanProgramID",
            "OriginationProgramID",
            "ProductID",
            "ProductJurisdictionID",
            "ClientID",
            "IsNotEligibleForBorrowerRefunds",
            "IsPaymentProcessingOnHold",
            "IsDoNotCall",
            "FIManager",
            "PaymentHistoryProfile",
            "IsExcludeFromCreditReporting",
            "CreatedDate",
            "CreatedByUserID",
            "LastModifiedDate",
            "LastModifiedByUserID",
            "GrossBalance",
            "IsPTO"
          ],
          "source_datatype_overrides": {},
          "source_primary_key_columns": [],
          "source_partition_key_columns": [],
          "source_filter_integer_values_only_columns": [],
          "source_chunksize_type": 1,
          "source_chunksize_integer": 0,
          "source_chunksize_boolean": true
        },
        "job_action_join_how": "left",
        "job_action_join_keys": [
          "ClientID"
        ]
      },
      "preview_dataset": {
        "job_action_schema_version": 1,
        "job_action_process_this_action": "no",
        "job_action_schedule_id": 0,
        "job_action_delete_destination_prior": 1,
        "job_action_source_use_connection_payload_override": 2,
        "job_action_source_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 0,
          "connection_user_id_key": 3337764179,
          "connection_tenant_id": 2855903462,
          "connection_tenant_company": "easyvet",
          "connection_user_email": "easyvet-dp-admin@easyvet.com",
          "connection_database": "2855903462",
          "connection_data_location": 2,
          "connection_type": 4,
          "connection_scope": 2,
          "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          "connection_bucket": "dashprophet-assets",
          "connection_subfolder": "datalake"
        },
        "job_action_source_data_payload": {
          "source_dataset_id": 1604278359,
          "source_folder_size": 1,
          "source_crawl_method": 0,
          "source_origin_name": "appointment_summary",
          "source_name": "appointment_summary",
          "source_scope": 1,
          "source_data_retrieval_method": 1,
          "source_data_retrieval_scope": 1,
          "source_filetype": 2,
          "source_classification_type": 7,
          "source_compression_type": 0,
          "source_query_statement": "select * from appointment_summary",
          "source_columns": [
            "*"
          ],
          "source_datatype_overrides": {},
          "source_primary_key_columns": [],
          "source_partition_key_columns": [],
          "source_filter_integer_values_only_columns": [],
          "source_chunksize_type": 1,
          "source_chunksize_integer": 0,
          "source_chunksize_boolean": true
        }
      },
      "delete_dataset": {
        "job_action_schema_version": 1,
        "job_action_process_this_action": "no",
        "job_action_schedule_id": 0,
        "job_action_delete_destination_prior": 1,
        "job_action_source_use_connection_payload_override": 2,
        "job_action_source_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 0,
          "connection_user_id_key": 0,
          "connection_tenant_id": 0,
          "connection_tenant_company": "",
          "connection_user_email": "",
          "connection_database": "",
          "connection_data_location": 2,
          "connection_type": 4,
          "connection_scope": 2,
          "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          "connection_bucket": "dashprophet-assets",
          "connection_subfolder": "datalake"
        },
        "job_action_source_data_payload": {
          "source_dataset_id": 0,
          "source_folder_size": 1,
          "source_crawl_method": 0,
          "source_origin_name": "",
          "source_name": "",
          "source_internal_name": "",
          "source_scope": 1,
          "source_data_retrieval_method": 1,
          "source_data_retrieval_scope": 1,
          "source_filetype": 2,
          "source_classification_type": 7,
          "source_compression_type": 0,
          "source_query_statement": "",
          "source_columns": [
            "*"
          ],
          "source_datatype_overrides": {},
          "source_primary_key_columns": [],
          "source_partition_key_columns": [],
          "source_filter_integer_values_only_columns": [],
          "source_chunksize_type": 1,
          "source_chunksize_integer": 0,
          "source_chunksize_boolean": true
        }
      },
      "refresh_dataset_now": {
        "job_action_process_this_action": "no",
        "job_action_dataset_id": 8176023459,
        "job_action_refresh_id_key": 1274065839
      },
      "delete_refresh_schedule": {
          "job_action_process_this_action": "no",
          "job_action_dataset_id": 1234567890,
          "job_action_refresh_id_key": 1234567890
      },
      "refresh_all_based_on_schedule": {
          "job_action_process_this_action": "no"
      },
      "export_dataset": {
        "job_action_schema_version": 1,
        "job_action_process_this_action": "yes",
        "job_action_delete_destination_prior": 1,
        "job_action_source_use_connection_payload_override": 2,
        "job_action_source_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 0,
          "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          "connection_tenant_id": 1,
          "connection_tenant_company": "",
          "connection_user_id_key": 0,
          "connection_user_email": "",
          "connection_type": 4,
          "connection_data_location": 2,
          "connection_driver": 0,
          "connection_host": "",
          "connection_port": 0,
          "connection_database": "1",
          "connection_database_schema": "",
          "connection_database_owner": "",
          "connection_external_key": "",
          "connection_username": "",
          "connection_password": "",
          "connection_secret_name": "",
          "connection_bucket": "dashprophet-assets",
          "connection_subfolder": "datalake"
        },
        "job_action_source_data_payload": {
          "source_delete_classification_after_successful_create": 1,
          "source_dataset_id": 0,
          "source_folder_size": 0,
          "source_crawl_method": 0,
          "source_origin_name": "",
          "source_name": "",
          "source_scope": 2,
          "source_data_retrieval_method": 1,
          "source_data_retrieval_scope": 2,
          "source_filetype": 2,
          "source_classification_type": 7,
          "source_compression_type": 0,
          "source_query_statement": "",
          "source_columns": [
            "*"
          ],
          "source_datatype_overrides": {},
          "source_primary_key_columns": [],
          "source_partition_key_columns": [],
          "source_filter_integer_values_only_columns": [],
          "source_chunksize_type": 1,
          "source_chunksize_integer": 0,
          "source_chunksize_boolean": true
        },
      "job_action_destination_use_connection_payload_override": 2,
      "job_action_destination_connection_payload": {
        "connection_schema_version": 1,
        "connection_id": 0,
        "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
        "connection_tenant_id": 1,
        "connection_tenant_company": "",
        "connection_user_id_key": 0,
        "connection_user_email": "",
        "connection_type": 12,
        "connection_data_location": 2,
        "connection_description": "",
        "connection_driver": 0,
        "connection_host": "",
        "connection_port": 0,
        "connection_database": "",
        "connection_database_schema": "",
        "connection_database_owner": "",
        "connection_external_key": "",
        "connection_username": "",
        "connection_password": "",
        "connection_secret_name": "",
        "connection_bucket": "dashprophet-assets",
        "connection_subfolder": "datalake"
      },
      "job_action_destination_data_payload": {
        "destination_dataset_id": 0,
        "destination_folder_size": 0,
        "destination_name": "",
        "destination_description": "",
        "destination_filetype": 8,
        "destination_scope": 2,
        "destination_compression_type": 0,
        "destination_classification_type": 86,
        "destination_columns": [
          "*"
        ],
        "destination_primary_key_columns": [],
        "destination_partition_key_columns": [],
        "destination_datatype_overrides": {},
        "destination_chunksize_type": 1,
        "destination_chunksize_integer": 0,
        "destination_chunksize_boolean": true,
        "destination_dataset_cleansing_provider": 0,
        "destination_dataset_cleansing_recipe_id": 0
      },
      "job_action_use_cleansing_payload_override": 0,
      "job_action_recipe_id": 0,
      "job_action_cleansing_payload": []
    },
    "delete_objects": {
      "job_action_schema_version": 1,
      "job_action_process_this_action": "no",
      "job_action_source_connection_payload": {
        "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
        "connection_bucket": "dashprophet-assets",
        "connection_subfolder": "datalake",
        "connection_tenant_id": 1,
        "connection_user_id_key": 961211902
      },
      "job_action_source_data_payload": {
        "source_s3_path": "",
        "source_s3_objects": []
      }
    },
    "move_objects": {
      "job_action_schema_version": 1,
      "job_action_process_this_action": "no",
      "job_action_source_connection_payload": {
        "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
        "connection_bucket": "dashprophet-assets",
        "connection_subfolder": "datalake",
        "connection_tenant_id": 1,
        "connection_user_id_key": 961211902
      },
      "job_action_source_data_payload": {
        "source_s3_path": "",
        "source_s3_objects": []
      },
      "job_action_destination_connection_payload": {
        "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
        "connection_bucket": "dashprophet-assets",
        "connection_subfolder": "datalake",
        "connection_tenant_id": 1,
        "connection_user_id_key": 961211902
      },
      "job_action_destination_data_payload": {
        "destination_s3_path": ""
      }
    },
    "copy_objects": {
      "job_action_schema_version": 1,
      "job_action_process_this_action": "no",
      "job_action_source_connection_payload": {
        "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
        "connection_bucket": "dashprophet-assets",
        "connection_subfolder": "datalake",
        "connection_tenant_id": 1,
        "connection_user_id_key": 961211902
      },
      "job_action_source_data_payload": {
        "source_s3_path": "",
        "source_s3_objects": []
      },
      "job_action_destination_connection_payload": {
        "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
        "connection_bucket": "dashprophet-assets",
        "connection_subfolder": "datalake",
        "connection_tenant_id": 1,
        "connection_user_id_key": 961211902
      },
      "job_action_destination_data_payload": {
        "destination_s3_path": ""
      }
    },
    "get_inbound_datasource_list": {
      "job_action_schema_version": 1,
      "job_action_process_this_action": "no",
      "job_action_schedule_id": 0,
      "job_action_project_id": 0,
      "job_action_auto_generate_project": 1,
      "job_action_project_payload": {},
      "job_action_delete_destination_prior": 1,
      "job_action_source_use_connection_payload_override": 2,
      "job_action_source_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 0,
          "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          "connection_tenant_id": 1,
          "connection_tenant_company": "",
          "connection_user_id_key": 0,
          "connection_user_email": "",
          "connection_type": 14,
          "connection_data_location": 2,
          "connection_api_connection_type": 0,
          "connection_api_source_provider": 0,
          "connection_bucket": "dashprophet-assets",
          "connection_subfolder": "datalake"
      },
      "job_action_source_data_payload": {
          "source_schema_version": 1,
          "source_dataset_id": 0,
          "source_folder_size": 1,
          "source_crawl_method": 0,
          "source_origin_name": "",
          "source_name": "",
          "source_scope": 2,
          "source_data_retrieval_method": 1,
          "source_data_retrieval_scope": 1,
          "source_filetype": 0,
          "source_classification_type": 1,
          "source_compression_type": 0,
          "source_query_statement": "",
          "source_columns": [
              "*"
          ],
          "source_datatype_overrides": {},
          "source_primary_key_columns": [],
          "source_partition_key_columns": [],
          "source_filter_integer_values_only_columns": [],
          "source_chunksize_type": 1,
          "source_chunksize_integer": 0,
          "source_chunksize_boolean": true
      }
    },
    "get_outbound_datasource_list": {
      "job_action_schema_version": 1,
      "job_action_process_this_action": "no",
      "job_action_schedule_id": 0,
      "job_action_project_id": 0,
      "job_action_auto_generate_project": 1,
      "job_action_project_payload": {},
      "job_action_delete_destination_prior": 1,
      "job_action_source_use_connection_payload_override": 2,
      "job_action_source_connection_payload": {
          "connection_schema_version": 1,
          "connection_id": 0,
          "connection_aws_account_id": `${process.env.REACT_APP_AWS_ACCOUNT_ID}`,
          "connection_tenant_id": 1,
          "connection_tenant_company": "",
          "connection_user_id_key": 0,
          "connection_user_email": "",
          "connection_type": 14,
          "connection_data_location": 2,
          "connection_api_connection_type": 0,
          "connection_api_source_provider": 0,
          "connection_bucket": "dashprophet-assets",
          "connection_subfolder": "datalake"
      },
      "job_action_source_data_payload": {
          "source_schema_version": 1,
          "source_dataset_id": 0,
          "source_folder_size": 1,
          "source_crawl_method": 0,
          "source_origin_name": "",
          "source_name": "",
          "source_scope": 2,
          "source_data_retrieval_method": 1,
          "source_data_retrieval_scope": 1,
          "source_filetype": 0,
          "source_classification_type": 1,
          "source_compression_type": 0,
          "source_query_statement": "",
          "source_columns": [
              "*"
          ],
          "source_datatype_overrides": {},
          "source_primary_key_columns": [],
          "source_partition_key_columns": [],
          "source_filter_integer_values_only_columns": [],
          "source_chunksize_type": 1,
          "source_chunksize_integer": 0,
          "source_chunksize_boolean": true
      }
  }
    },
    'status': 1,
    'status_change_datetime': '',
    'created_datetime': '',
    'created_by_user': '',
    'last_modified_datetime': '',
    'last_modified_by_user': '',
    'marked_for_deletion_datetime': '',
    'marked_for_deletion_by_user': ''
  }
});

export default getOdbcActions;
