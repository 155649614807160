import React from 'react';
import { NavLink } from 'react-router-dom';
import { IconM } from '../Icon';
import useUser from 'Hooks/Account/useUser';
import { useNavigationTrigger } from 'Hooks/Navigation/useNavigationTrigger';
import { ReactComponent as AnalystIcon } from 'Assets/icons/group_15.svg';
import { permittedRoutes }  from 'Utilities/permittedRoutes';

const Menu = (props) => {
  const { user } = useUser();

  const { classes } = props;
  const { handleCloseNavigation } = useNavigationTrigger();

  const navElements = [
    {
      to: 'home',
      label: 'Home',
      icon: 'house-2'
    },
    {
      to: 'my_data',
      label: 'My Data',
      icon: 'app-window-cloud'
    },
    {
      to: 'my_projects',
      label: 'My Projects',
      icon: 'task-checklist'
    },
    {
      to: 'my_dashboard',
      label: 'Dashboards',
      icon: 'gauge-dashboard-1-alternate'
    },
    {
      to: 'messages',
      label: 'Messages',
      icon: 'messages-bubble-double-alternates'
    },
    {
      to: 'my_analysts',
      label: 'My Analysts'
    },
    {
      to: 'my_teams',
      label: 'My Teams',
      icon: 'multiple-users-1'
    }
  ];

  return (
    <>
      {navElements.map((item, key) => {
        const navClass = user && permittedRoutes[user['custom:application_role']].includes(item.to) ? classes.nav_link : classes.nav_link_disabled;
        return (
          <NavLink
            key={key}
            className={navClass}
            to={`/${item.to}`}
            activeClassName={classes.active}
            onClick={handleCloseNavigation}
            data-alt={item?.alt}
          >
            <div className={classes.nav_link_icon}>
              {item.icon ?
                <IconM size={10} icon={item.icon}/>
                :
                <AnalystIcon />
              }
            </div>
            {item.label}
          </NavLink>
        );
      })}
    </>
  );
};

export default Menu;
