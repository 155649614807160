import React, { useCallback, useState } from 'react';
import { mergeClasses } from 'classify';
import { useDailogBox } from '../../Context/DailogBox';
import defaultClasses from './dialogBox.module.scss';
import { Modal } from 'Components/Modal';
import DowngradeRoleTable from '../DowngradeRoleTable/downgradeRoleTable';
import ConfirmationDialog from '../ConfirmationDialog/confirmationDialog';

const ConfirmationPopup = (props) => {

  const classes = mergeClasses(defaultClasses, props.classes);
  const [isSubmiting, setSubmiting] = useState(false);
  const [{
    message,
    title,
    // icon,
    onConfirm,
    onCancel: cancelHandler,
    visible,
    type, //success | confirm | failure
    firstButtonName,
    secondButtonName,
    htmlContent
  }, {
    closeDailog
  }] = useDailogBox();

  const onSuccess = useCallback(async() => {
    setSubmiting(true);
    if (onConfirm) {
      await onConfirm();
    }
    setSubmiting(false);
    closeDailog();
  }, [
    setSubmiting,
    onConfirm,
    closeDailog
  ]);

  const onCancel = useCallback(async() => {
    setSubmiting(true);
    if (cancelHandler) {
      await cancelHandler();
    }
    setSubmiting(false);
    closeDailog();
  }, [
    setSubmiting,
    cancelHandler,
    closeDailog
  ]);

  const talonProps = {type, title, onSuccess, isSubmiting, onCancel, message, firstButtonName, secondButtonName, htmlContent };

  const rootClass = visible
    ? `${classes.root_open} modal fade  show`
    : `modal fade  ${classes.root}`;
  return (
    <Modal>
      <aside className={rootClass}>
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className={`modal-content ${type != 'downgrade_role' ? classes.modal_content : classes.downgrade_content}`}>
            {type != 'downgrade_role' ? <ConfirmationDialog {...talonProps} />: <DowngradeRoleTable {...talonProps} />}
          </div>
        </div>
      </aside>
    </Modal>
  );
};

export default ConfirmationPopup;
