/* eslint-disable camelcase */
import axios from 'axios';

/**
 * Create a new tenant(user)
 *
 * @param {Object} user
 * @param {String} user.first_name
 * @param {String} user.last_name
 * @param {String} user.email
 * @param {String} user.company_name
 * @param {String} user.tier
 * @param {String} user.user_name
 */
export const signup = async(data) => {
  try {
    const response = await axios.post('/reg', data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * Tenant Login OR  Reset Password Login
 *
 * @param {Object} credentials
 * @param {String} credentials.userName
 * @param {String} credentials.password
 * @param {String} user.newPassword
 */
export const login = async(credentials) => {
  try {
    const response = await axios.post('/auth', credentials);

    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const verifyEmail = async(token) => {
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const res = await axios.get('/auth/verifyEmail');
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const resendEmail = async(payload) => {
  try {
    return await axios.post('auth/resendEmail', payload);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const logout = async() => {
  try {
    // return await axios.post("auth/logout");
    localStorage.clear();
    return;
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const refreshAccessToken = async(email) => {

  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const bearerToken = localStorage.getItem('token');
    const getTokenUrl = `${axios.defaults.baseURL}/auth/refresh`;
    const data = JSON.stringify({ 'user_name': email, 'password': 'null', 'refreshToken': refreshToken });

    if (refreshToken) {

      const response = await fetch(getTokenUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          Accept: 'application/json'
        },
        body: data
      });
      const { token, access: accessToken, refresh: { token: refreshToken } } = await response.json();

      if (token && refreshToken) {
        setTokenStore({accessToken, refreshToken, token});
      } else {
        // localStorage.clear();
        window.location = '/';
      }
      return token;
    }
  } catch (error) {
    // localStorage.clear();
  }
};
export async function setTokenStore({ accessToken, refreshToken, token, user, tenant, paymentStatus }) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('token', token);
  user && localStorage.setItem('user', JSON.stringify(user));
  tenant && localStorage.setItem('tenant', JSON.stringify(tenant));
  paymentStatus && localStorage.setItem('paymentStatus', paymentStatus);
}
export async function setToken(accessToken, refreshToken) {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
}

export const forgotPassword = async(userName) => {
  try {
    return await axios.post('auth/forgotPassword', userName);
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export const resetPassword = async({userName, code, newPassword}) => {
  try {
    const response = await axios.post('auth/resetPassword', {
      userName,
      code,
      newPassword
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
};

export const getUser = async() => {
  try {
    const res = await axios.get('/user');
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const quickSightSession = async() => {
  /* eslint-disable no-useless-catch*/
  try {
    const res = await axios.post('/user/qs');
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const quicksight = async() => {
  try {
    const res = await axios.post('/user/create-open-id-connect');
    console.log(res);
  } catch (error) {
    throw error;
  }
};


