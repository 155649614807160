import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { makeServer } from './mocks/server';
import { Server } from 'miragejs';

import 'index.scss';
import * as serviceWorker from 'serviceWorker';
import store from 'store';
import Adapter from 'adapter';
import App, { AppContextProvider } from 'Layouts/App';
// import parseJwt from 'Utilities/parseJwt';
// import {refreshAccessToken} from 'Services/authServices';

if (window.Cypress) {


  // mirage cypress server
  const cyServer = new Server({
    routes() {
      ['get', 'put', 'patch', 'post', 'delete'].forEach((method) => {
        this[method]('/*', (schema, request) => window.handleFromCypress(request));
      });
    }
  });
  cyServer.logging = false;
} else if (process.env.REACT_APP_PROXY) {
  // mirage dev server
  makeServer();

}


// Axios default configuration
axios.defaults.baseURL = process.env.REACT_APP_PROXY ? `${process.env.PUBLIC_URL}/api` : process.env.REACT_APP_AWS_END_POINT;
const token = localStorage.getItem('token');
// const user = token && parseJwt(token);

if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}


ReactDOM.render(
  <React.StrictMode>
    <Adapter store={store}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Adapter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
