import React, { useCallback, useEffect, lazy, Suspense, memo, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.scss';
import { Steps} from 'intro.js-react';

import { HTML_UPDATE_AVAILABLE } from 'Constants/swMessageTypes';
import { registerMessageHandler } from 'Utilities/swUtils';
import Main from '../Main';
import Mask from 'Components/Mask';
import Routes from '../Router';
import { useApp } from 'Hooks/App/useApp';
import ProgressBarContainer from 'Components/ProgressBarContainer';
import { ToastContainer } from 'react-toastify';
import DailogBoxContainer from 'Components/DailogBoxContainer';
import TooltipContainer from 'Components/TooltipContainer';
import { ReactComponent as RightArrow } from 'Assets/icons/right_arrow.svg';
import { ReactComponent as LeftArrow } from 'Assets/icons/left_arrow.svg';

// import Adroll from 'Hooks/App/adroll.js';
import Hotjar from '@hotjar/browser';

import 'intro.js/introjs.css';

const SideNavMobile = lazy(() => import('Components/SideNavMobile'));

const App = (props) => {
  const { markErrorHandled, renderError, unhandledErrors } = props;
  const [isTourActive, setIsTourActive] = useState(false);

  // hotjar creds
  const siteId = 3385598;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  const ref = useRef();
  const location = useLocation();

  const handleIsOffline = useCallback(() => { }, []);

  const handleIsOnline = useCallback(() => { }, []);

  const handleHTMLUpdate = useCallback(
    async(resetHTMLUpdateAvaiableFlag) => { },
    []
  );

  const handleError = useCallback((error, id, loc, handleDismissError) => { },
    []);

  const talonProps = useApp({
    handleError,
    handleIsOffline,
    handleIsOnline,
    handleHTMLUpdate,
    markErrorHandled,
    renderError,
    unhandledErrors
  });

  const {
    isMobileNav,
    hasOverlay,
    handleCloseDrawer,
    setHTMLUpdateAvailable
  } = talonProps;

  //const { handleTokenError } = useErrorToken();


  // adroll tracker call
  // useEffect(() => {
  //   Adroll.init('O7JR6WEZDZFRVHBOXRON63', 'OV4THHW3JBCCFINZIBIHU7', false);
  //   // Adroll.recordUser('12345abcdefg');
  // }, []);

  useEffect(() => {
    const unregisterHandler = registerMessageHandler(
      HTML_UPDATE_AVAILABLE,
      () => {
        setHTMLUpdateAvailable(true);
      }
    );
    // handleTokenError();

    return unregisterHandler;
  }, [setHTMLUpdateAvailable]);

  useEffect(() => {
    if (location.pathname === '/home') {
      setTimeout(() => {
        setIsTourActive(false);
      }, 1000);
    } else {
      setIsTourActive(false);
    }
  }, [location]);

  if (renderError) {
    return (
      <>
        <Main isMasked={true} />
        <Mask isActive={true} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
        />
        {/* <ToastContainer /> */}
      </>
    );
  }

  const _mobleNav = isMobileNav ? (
    <Suspense fallback={<div/>}>
      <SideNavMobile />
    </Suspense>
  ) : null;

  const StepFunction = (props) => {
    const classChild = props.arrow;
    return (
      <div className="step_fun">
        {props.arrow === 'R' ? <span><RightArrow /></span> : <LeftArrow /> }
        <span className={classChild}>
        Lorem ipsum dolor sit amet ,consectetur adipiscing elit.
        Donec efficitur interdum justo, quis viverra erat hendrerit sed.
        </span>
      </div>
    );
  };

  const steps = [
    {
      element: '.grid',
      intro: '<div><p>Hi Username!</p><h2>Welcome to Dashprophet.</h2>' +
      '<p>Click Tips and Tricks to get the guide. We have a S&P 500 stock data set to get you started in My Data</p></div>',
      highlightClass: 'highlight_class',
      tooltipClass: 'mask_class',
      scrollToElement: true

    },
    {
      element: '.tips',
      intro: <StepFunction arrow="L" class="svg_class"/>,
      tooltipClass: 'mask_class',
      highlightClass: 'highlight_class',
      skipLabel: 'Skip',
      scrollToElement: true
    },
    {
      element: '.dropdown',
      tooltipClass: 'mask_class_btn',
      highlightClass: 'highlight_class',
      intro: <StepFunction arrow="R"/>
    }
  ];


  return (
    <>
      <Main isMasked={hasOverlay}>
        <Routes />
      </Main>
      <Mask isActive={hasOverlay} dismiss={handleCloseDrawer} />
      {_mobleNav}
      <DailogBoxContainer />
      <ToastContainer />
      <ProgressBarContainer />
      {/* <ToastContainer /> */}
      <TooltipContainer
        position="top-right"
        autoClose={5000}
      />
      {isTourActive && <Steps
        steps={steps}
        // onStart={() => {document.}}
        onExit={() => {}}
        onStart={() => {
          const body = document.querySelector('body');
          body.style.overflow = 'hidden';
        }}
        initialStep={0}
        enabled={isTourActive}
        ref={(steps) => ref.current = steps}
        onChange={(nextStepIndex) => {
          // if (nextStepIndex === 1) {
          ref.current && console.log({ref});
          ref.current && ref.current.updateStepElement(nextStepIndex);
          // }
        }}
        // onBeforeChange= {nextStepIndex => {
        //   if (nextStepIndex === 1) {
        //     const sideNav = document.querySelector('.sideNav_sidenav_menu__DRL0M')
        //     sideNav.style.overflowY = "scroll"
        //   }
        // }}

        options={{
          showBullets: false,
          exitOnOverlayClick: false,
          disableInteraction: false,
          scrollToElement: true
        }}
      />}
    </>
  );
};

export default memo(App);
