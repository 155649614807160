import React from 'react';

import { mergeClasses } from 'classify';
import defaultClasses from './adminLayout.module.scss';

import HeaderDesktop from 'Components/Header';
import HeaderMobile from 'Components/HeaderMobile';

import Footer from 'Components/Footer';
import SideNav from 'Components/SideNav';

import useUser from 'Hooks/Account/useUser';
import { useAppContext } from 'Context/app';

const AdminLayout = (props) => {
  const { children } = props;
  const classes = mergeClasses(defaultClasses, props.classes);
  const { Signout, user } = useUser();
  const appContext = useAppContext();
  const [{ is404 }] = appContext;
  const [{ whichDeviceActive }] = appContext;
  const paymentStatus = localStorage.getItem('paymentStatus');

  const isRestricted = user['custom:application_role'] === '4' || user['custom:application_role'] === '1' && +paymentStatus !== 3;
  const desktopClasses = user['custom:application_role'] === '4' ? classes.deskLayoutSidenav_reader : classes.deskLayoutPayment;

  return (
    <>
      <HeaderDesktop user={user} Signout={Signout} isAuthenticated={true} isHidden={whichDeviceActive != 'desktop'} />
      <HeaderMobile isHidden={whichDeviceActive == 'desktop'} />
      <div className={whichDeviceActive == 'desktop' ? classes.deskLayoutSidenav : classes.mobLayoutSidenav}>
        {whichDeviceActive == 'desktop' && (
          isRestricted ?
            null
            : <div className={classes.deskLayoutSidenav_nav}>
              <SideNav />
            </div>
        )}
        <div className={whichDeviceActive == 'desktop' ?
          (
            isRestricted ? desktopClasses : classes.deskLayoutSidenav_content
          ) : (
            classes.mobLayoutSidenav_content
          )}>
          <div className={`${classes.container} p-4 ${is404 ? classes.paddingLess : null}`}>{children}</div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminLayout;
