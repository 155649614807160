import { useEffect } from 'react';
import { useAppContext } from 'Context/app';

export const useHeader = () => {
  const [{ drawer }, { closeDrawer }] = useAppContext();
  const isFullWidthDashboard = drawer === 'dashboardFullwidth';
  useEffect(() => function() {
    closeDrawer();
  }, []);
  return {
    isFullWidthDashboard,
    closeDrawer
  };
};
