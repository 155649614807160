import React from 'react';

import { mergeClasses } from 'classify';
import defaultClasses from './mask.module.scss';
import { useAppContext } from 'Context/app';

const Mask = (props) => {
  const classes = mergeClasses(defaultClasses, props.classes);
  const { dismiss, isActive } = props;
  const [{ drawer }] = useAppContext();

  const className = drawer === 'dashboardFullwidth' ?
    classes.root_active_fullscreen : isActive ? classes.root_active : classes.root;

  return <button className={className} onClick={dismiss} />;
};

export default Mask;
