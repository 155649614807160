import React from 'react';
import { mergeClasses } from 'classify';
import defaultClasses from './confirmationDialog.module.scss';
import { IconM } from '../Icon';

const ConfirmationDialog = (props) => {
  const classes = mergeClasses(defaultClasses, props.classes);
  return (
    <>
      <div className={`${classes.modal_body} py-3 pb-0`}>
        {props.type == 'success' ? (<div className={`${classes.iconContainerDailog}`}>
          <IconM size={46} icon="success_icon" />
        </div>) : null}
        {props.title ? <h5>{props.title}</h5> : null}
        {props.htmlContent && <div dangerouslySetInnerHTML={{__html: props.htmlContent}} className="p-3"/>}
        {props.title && props.message ? <div className={`${classes.border_bottom}`} /> : null}
        {props.message ? <p className="p-2">{props.message}</p> : null}
      </div>
      <div className={`${classes.modal_footer} pb-4`}>
        {props.type == 'confirm' && !!props.firstButtonName ? <button type="button" className={`${classes.btn} ${classes.btn_secondary}`} onClick={props.onCancel}>
          {props.firstButtonName}
        </button> : null}
        <button type="button" className={`${classes.btn} ${classes.btn_primary}`} onClick={props.onSuccess} disabled={props.isSubmiting}>
          {props.secondButtonName}
        </button>
      </div>
    </>
  );
};

ConfirmationDialog.defaultProps = {
  firstButtonName: 'No',
  secondButtonName: 'Yes'
};


export default ConfirmationDialog;
