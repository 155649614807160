import React, { Component, Fragment } from 'react';
import { node, number, oneOfType, shape, string } from 'prop-types';
import { BasicText, asField } from 'informed';
import { compose } from 'redux';

import classify, { mergeClasses }  from 'classify';
import { FieldIcons, Message } from 'Components/Field';
import defaultClasses from './textInput.module.scss';

export class TextInput extends Component {
  static propTypes = {
    after: node,
    before: node,
    classes: shape({
      input: string
    }),
    fieldState: shape({
      value: oneOfType([string, number])
    }),
    message: node
  };

  constructor(props) {
    super(props);
    this.state = {
      hidden: true
      // password: ''
    };
    // this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden});

  }

  render() {
    const { after, before, fieldState, message, toggle, classes: propClasses, ...rest } = this.props;
    const classes = mergeClasses(defaultClasses, propClasses);

    return (
      <Fragment>
        <FieldIcons after={after} before={before}>
          <BasicText
            {...rest}
            type={toggle ? this.state.hidden ? 'password' : 'text' : this.props.type}
            spellCheck="false"
            fieldState={fieldState}
            className={`${fieldState.error === 'error' ? classes.input_error : classes.input} form-control `}
          />
          {toggle ?
            <span type="button" className={classes.button} priority="low" onClick={this.toggleShow}>{this.state.hidden ? 'Show' : 'Hide' }</span>
            : null }

        </FieldIcons>
        {fieldState.error !== 'error' && (
          <Message fieldState={fieldState}>{message}</Message>
        )}
      </Fragment>
    );
  }
}

export default compose(classify(defaultClasses), asField)(TextInput);
