import React, { createContext, useContext, useReducer } from 'react';
import { toast } from 'react-toastify';

import withLogger from 'Utilities/withLogger';
import { updateDataset, odbcActionService, deleteDataset } from 'Services/dataSetServices';
import getOdbcActions from 'Constants/odbcActions.js';
import { ACTIONS } from 'Constants/jobMasterActions.js';
import { toSnakeCase } from 'Utilities/toSnakeCase';


const initialState = {
  files: [],
  uploadedFiles: [],
  isExpanded: true,
  progressFiles: [],
  isDetailed: [],
  configuration: []
};
const checkFiles = [];

/** Function is used to delete all other actions that not be performed  */
const deleteActions = (action, payload) => {
  ACTIONS.forEach((element) => {
    element !== action && delete payload[element];
  });
};

const datasetStatusUpdate = (uploadData, user) => {
  // filtering complete uploaded files
  const uploadCompleteFiles = uploadData.filter((prev) => prev.progress === 100);

  const tempArray = new Set();
  uploadCompleteFiles.length && uploadCompleteFiles.forEach((file) => {
    const uploadFiles = uploadData.filter((item) => item.id === file.id && item.progress === 100);
    file.count === uploadFiles.length && tempArray.add(file.id);
  });

  //update dataset status as upload completed and triggers dataset etl job actions
  for (const item of tempArray) {
    if (item && !checkFiles.includes(item)) {
      updateDataset(item, {dataset_status: 4});
      const odbcActions = getOdbcActions();
      const dataset = uploadData.find((element) => element.id === item);

      deleteActions('create_dataset', odbcActions.job_master.job_master_action_payload);

      const datasetName = toSnakeCase(dataset.dataset.dataset_name);
      try {
        odbcActions.job_master.job_master_job_owner_tenant_id_key = user.tenant_id_key;
        odbcActions.job_master.job_master_job_owner_tenant_company = user.company_name;
        odbcActions.job_master.job_master_job_owner_email = user.email;
        odbcActions.job_master.job_master_job_owner_user_name = user.user_name;
        odbcActions.job_master.user_id_key = +user['custom:id_key'];

        const createDataset = odbcActions.job_master.job_master_action_payload.create_dataset;
        createDataset.job_action_process_this_action = 'yes';
        createDataset.job_action_source_connection_payload.connection_type = 12;
        createDataset.job_action_source_connection_payload.connection_data_location = 2;
        createDataset.job_action_source_connection_payload.connection_user_email=user.email;
        createDataset.job_action_source_connection_payload.connection_user_id_key=+user['custom:id_key'];
        createDataset.job_action_source_connection_payload.connection_tenant_id=user.tenant_id_key;
        createDataset.job_action_source_connection_payload.connection_database=String(user.tenant_id_key);
        createDataset.job_action_source_connection_payload.connection_tenant_company = user.company_name;
        createDataset.job_action_source_connection_payload.connection_id = 0;
        createDataset.job_action_source_connection_payload.connection_aws_account_id = `${process.env.REACT_APP_AWS_ACCOUNT_ID}`;
        createDataset.job_action_source_connection_payload.connection_schema_version = 1;
        createDataset.job_action_source_connection_payload.connection_driver = 0;
        createDataset.job_action_source_connection_payload.connection_host = '';
        createDataset.job_action_source_connection_payload.connection_port = 0;
        createDataset.job_action_source_connection_payload.connection_database_schema = 'dbo';
        createDataset.job_action_source_connection_payload.connection_database_owner = '';
        createDataset.job_action_source_connection_payload.connection_external_key = '';
        createDataset.job_action_source_connection_payload.connection_username = '';
        createDataset.job_action_source_connection_payload.connection_password = '';
        createDataset.job_action_source_connection_payload.connection_secret_name = '';
        // createDataset.job_action_source_connection_payload.connection_bucket = `${process.env.REACT_APP_ENV}-dashprophet-assets`;
        createDataset.job_action_source_connection_payload.connection_bucket = `dashprophet-assets`;
        createDataset.job_action_source_connection_payload.connection_subfolder = 'datalake';
        createDataset.job_action_source_connection_payload.connection_import_mode = 'SPICE';
        createDataset.job_action_source_data_payload.source_scope = dataset.dataset.dataset_scope;
        createDataset.job_action_source_data_payload.source_dataset_id = item;
        createDataset.job_action_source_data_payload.source_name = dataset.dataset.dataset_name;
        createDataset.job_action_source_data_payload.source_origin_name = dataset.dataset.dataset_name;
        createDataset.job_action_source_data_payload.source_classification_type = 3;
        createDataset.job_action_source_data_payload.source_data_retrieval_scope = 1;
        createDataset.job_action_source_data_payload.source_data_retrieval_method = 1;
        createDataset.job_action_source_data_payload.source_filetype = dataset.fileType;
        createDataset.job_action_source_data_payload.source_internal_name = datasetName;
        createDataset.job_action_source_data_payload.source_cleansing_payload = [];
        createDataset.job_action_source_data_payload.source_use_cleansing_payload_override = 2;
        createDataset.job_action_source_data_payload.source_recipe_id = 0;
        createDataset.job_action_source_data_payload.source_columns = ['*'];

        createDataset.job_action_destination_connection_payload.connection_type = 4;
        createDataset.job_action_destination_connection_payload.connection_data_location = 2;
        createDataset.job_action_destination_connection_payload.connection_user_email=user.email;
        createDataset.job_action_destination_connection_payload.connection_user_id_key=+user['custom:id_key'];
        createDataset.job_action_destination_connection_payload.connection_tenant_id=user.tenant_id_key;
        createDataset.job_action_destination_connection_payload.connection_database=String(user.tenant_id_key);
        createDataset.job_action_destination_connection_payload.connection_tenant_company = user.company_name;
        createDataset.job_action_destination_data_payload.destination_scope = dataset.dataset.dataset_scope;
        createDataset.job_action_destination_data_payload.destination_dataset_id = item;
        createDataset.job_action_destination_data_payload.destination_columns = ['*'];
        createDataset.job_action_destination_data_payload.destination_description = dataset.dataset.dataset_description;
        createDataset.job_action_destination_data_payload.destination_name = datasetName;
        createDataset.job_action_destination_data_payload. destination_dataset_resume_configuration = 2;
        createDataset.job_action_destination_data_payload.destination_internal_name = datasetName;
        createDataset.job_action_destination_data_payload.destination_classification_type = dataset.dataset.destination_classification.value;
        createDataset.job_action_destination_data_payload.destination_refresh_update_method = 2;

        odbcActionService(odbcActions).then((res) => {
          if (res.data.errorMessage) {
            toast.error(res.data.errorMessage);
            deleteDataset(item, 4);
          }
        }).catch((err) => {
          toast.error(err);
        });
        checkFiles.push(item);
      } catch (err) {
        console.log(err);
      }
    }
  }
  return uploadCompleteFiles;
};

const reducer = (prevState = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case 'show': {
      // check if the same file name exists in prevState, update the percentage
      const uploadData = [...prevState.files];
      payload.forEach((item) => {
        const indexOfItem = uploadData.findIndex((prevItem) => prevItem.id === item.id && prevItem.name === item.name);
        if (indexOfItem > -1) {
          uploadData[indexOfItem].progress = item.progress;
        } else {
          uploadData.push(item);
        }
      });
      const uploadCompleteFiles =  datasetStatusUpdate(uploadData, action.user);
      return  {...prevState, files: uploadData, uploadedFiles: uploadCompleteFiles};
    }
    case 'hide': {
      return initialState;
    }
    case 'toggle': {
      return {
        ...prevState,
        isExpanded: payload
      };
    }
    case 'configurationHide': {
      const configurationName = prevState.configuration;
      configurationName.push(payload);
      return {
        ...prevState,
        configuration: configurationName
      };
    }
    case 'datasetToggle': {
      const datasetNames = prevState.isDetailed;
      if (!datasetNames.includes(payload)) {
        datasetNames.push(payload);
      } else {
        const indexOfItem = datasetNames.findIndex((item) => item === payload);
        datasetNames.splice(indexOfItem, 1);
      }
      return {
        ...prevState,
        isDetailed: datasetNames
      };
    }
    case 'cancel': {
      let cancelFile = prevState.files.length ? [...prevState.files] : [...prevState.progressFiles];
      if (!localStorage.getItem('token')) {
        cancelFile = [];
      } else {
        cancelFile.forEach((item) => {
          if (item.name === payload.name && item.isJoin === payload.join) {
            item.progress =  0;
            item.status = 'failed';
          }
        });
      }

      return {
        ...prevState,
        files: prevState.files.length ? cancelFile : [],
        progressFiles: cancelFile
      };
    }
    case 'retry': {
      const retryFile = [...prevState.files];
      retryFile.forEach((item) => {
        if (item.name === payload.name) {
          item.progress =  0;
          item.status = 'uploading';
          item.cancelSource = payload.cancelSource;
        }
      });

      return {
        ...prevState,
        files: retryFile
      };
    }
    case 'close' : {
      if (prevState.files.length != prevState.uploadedFiles.length) {
        action.confirmDailogBox({
          title: `Are you sure want to logout?`,
          onConfirm: async() => {
            prevState.files.forEach((item) => {
              item.progress != 100 &&
              item.cancelSource.cancel('upload canceled by user');
            });
            prevState.files=[];
            prevState.isCancel=true;
            action.Signout();
          }
        });
      } else {
        prevState=initialState;
        action.Signout();
      }
      return prevState;
    }
    case 'progress' : {
      const uploadData = [...prevState.progressFiles];
      payload.forEach((item) => {
        const indexOfItem = uploadData.findIndex((prevItem) => prevItem.name === item.name && prevItem?.isJoin === item?.isJoin);
        if (indexOfItem > -1) {
          uploadData[indexOfItem].progress = item.progress;
        } else {
          uploadData.push(item);
        }
      });
      return  {...prevState, progressFiles: uploadData};
    }
    case 'delete': {
      let tempFiles = [];
      if (payload?.action === 'delete') {
        tempFiles = [...prevState.progressFiles].filter((item) => item.name != payload.name);
      }
      return  {...prevState, progressFiles: tempFiles};
    }
    default:
      return initialState;
  }
};

const ProgressBarContext = createContext();

const wrappedReducer = withLogger(reducer);

export const ProgressBarContextProvider = ({ children }) => {
  const store = useReducer(wrappedReducer, initialState);
  return (
    <ProgressBarContext.Provider value={store}>{children}</ProgressBarContext.Provider>
  );
};

export const useProgressBarContext = () => useContext(ProgressBarContext);

