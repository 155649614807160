import React, {
  createContext,
  useReducer,
  useContext
} from 'react';
import withLogger from '../../Utilities/withLogger';

const initialState = {
  message: null,
  title: null,
  icon: null,
  onConfirm: undefined,
  onCancel: undefined,
  visible: false,
  type: 'success' // || success | confirm | failure
};

const reducer = (prevState = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case 'confirm': {
      return {
        ...prevState,
        ...payload,
        type: 'confirm',
        visible: true
      };
    }
    case 'success': {
      return {
        ...prevState,
        ...payload,
        type: 'success',
        visible: true
      };
    }
    case 'failure': {
      return {
        ...prevState,
        ...payload,
        type: 'failure',
        visible: true
      };
    }
    case 'reset': {
      return {
        ...initialState
      };
    }
    case 'downgrade_role': {
      return {
        ...prevState,
        ...payload,
        type: 'downgrade_role',
        visible: true
      };
    }
    default:
      return prevState;
  }
};

const DailogBoxContext = createContext();

const wrappedReducer = withLogger(reducer);


export const DailogBoxContextProvider = ({ children }) => {
  const store = useReducer(wrappedReducer, initialState);
  return (
    <DailogBoxContext.Provider value={store}>{children}</DailogBoxContext.Provider>
  );
};

export const useDailogBoxContext = () => useContext(DailogBoxContext);
