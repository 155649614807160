import actions from './actions';

export const toggleDrawer = (name, data) => async(dispatch) =>
  dispatch(actions.toggleDrawer({ name, data }));

export const closeDrawer = () => async(dispatch) =>
  dispatch(actions.toggleDrawer({ name: null }));

export const toggleSearch = () => async(dispatch) =>
  dispatch(actions.toggleSearch());

export const changeDevice = (name) => async(dispatch) =>
  dispatch(actions.changeDevice(name));

export const is404 = (value) => async(dispatch) =>
  dispatch(actions.is404(value));

export const togglePreload = () => async(dispatch) =>
  dispatch(actions.togglePreload());

export const webSocket = (ws) => async(dispatch) =>
  dispatch(actions.webSocket(ws));

export const setNotification = (count) => async(dispatch) =>
  dispatch(actions.setNotification(count));

export const Login = () =>
  async function thunk(dispatch) {
    //
    await dispatch(actions.login.request());
    await dispatch(actions.login.success());
    await dispatch(actions.login.falilure());
  };

export const setGlobalProgressBar = (payload) => (dispatch) => {
  dispatch(actions.gloabalProgressBar(payload));
};

export const stateToServiceWorker = ({ type, payload, navigator = window?.navigator }) =>
  async function thunk(dispatch) {
    const sw = navigator.serviceWorker;
    //
    if (sw?.controller) {
      sw.controller.postMessage({ type, payload });
    }
    await dispatch(actions.serviceWorkerMessage({ type, payload }));
  };
