import React from 'react';
import { Form } from 'informed';
import { mergeClasses } from 'classify';
import SearchField from './searchField';
import { useSearchBar } from 'Hooks/SearchBar/useSearchBar';
import defaultClasses from './searchBar.module.scss';
import { useHistory } from 'react-router-dom';

const Search = (props) => {
  const classes = mergeClasses(defaultClasses, props.classes);
  const history = useHistory();

  function handleSearch() {
    history.push('/support_tips');
  }

  const talonProps = useSearchBar();
  const {
    handleChange,
    handleFocus
    // handleSubmit
  } = talonProps;
  return (
    <Form
      data-testid="search"
      autoComplete="off"
      className={classes.root}
      // initialValues={props.searchValue}
      onSubmit={handleSearch}
    >
      <div className={classes.input_group}>
        <SearchField
          onChange={handleChange}
          onFocus={handleFocus}
          classes={{
            input: classes.input
          }}
          placeholder={props.placeholder}
          searchValue={props.searchValue}
        />
      </div>
    </Form>
  );
};

export default Search;
