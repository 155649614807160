import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { mergeClasses } from 'classify';

import defaultClasses from './header.module.scss';
import SearchBar from '../SearchBar';
import ProfileDropdown from './profileDropdown';
import NotificationDropdown from './notification';
import { useHeader } from 'Hooks/Header/useHeader';
import Icon from '../Icon';
import { ChevronLeft } from 'react-feather';

const Header = (props) => {
  const { user, isAuthenticated, Signout, isHidden } = props;
  const classes = mergeClasses(defaultClasses, props.classes);

  const history = useHistory();
  const { isFullWidthDashboard, closeDrawer } = useHeader();

  const isExpandedIframe = history.location.state?.expandIframe;

  return (
    <nav className={`classes.root ${isHidden ? classes.navHide : classes.navShow}`}>
      <Link to='/home' className={classes.navbar_brand} onClick={() => isFullWidthDashboard && closeDrawer()}>
        <img
          className={classes.logo}
          src={require('Assets/logo.png')}
          alt="DashProphet"
        />
      </Link>
      {isAuthenticated && !isExpandedIframe && (
        <>
          <SearchBar placeholder="Search Knowledge Base..." />

          <ul className={classes.navbar_nav}>
            <NotificationDropdown />
            <ProfileDropdown user={user} Signout={Signout} />

          </ul>
        </>
      )}
      {isExpandedIframe ?<> <div className={classes.outer_div_nav}>

      </div>
      <button className={`btn btn-sm ${classes.back_btn}`} onClick={() => {history.push({state: {expandIframe: false}});}}>
        <Icon src={ChevronLeft} />
        <span>Back</span>
      </button></>
        : null}
    </nav>
  );
};

export default Header;
