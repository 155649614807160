export const ACTIONS = [
  'get_datasource_list',
  'validate_data_connection',
  'create_dataset',
  'create_dataset_from_join',
  'create_dashboard',
  'refresh_dataset',
  'get_metadata',
  'create_analysis',
  'preview_dataset_from_join',
  'preview_dataset',
  'refresh_dataset_from_join',
  'delete_dataset',
  'refresh_dataset_now',
  'delete_refresh_schedule',
  'refresh_all_based_on_schedule',
  'get_inbound_datasource_list',
  'get_outbound_datasource_list',
  'export_dataset',
  'delete_objects',
  'move_objects',
  'copy_objects'
];
