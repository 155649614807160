import React, { createContext, useContext, useReducer } from 'react';
import withLogger from '../../Utilities/withLogger';
const initialState = {
  show: false,
  x: 0,
  y: 0,
  orientLeft: false,
  content: ''
};


const reducer = (prevState = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case 'show': {


      return {
        ...prevState,
        ...payload
      };
    }
    case 'hide': {
      return {
        ...prevState,
        ...payload
      };
    }
    default:
      return prevState;
  }
};

const TooltipContext = createContext();

const wrappedReducer = withLogger(reducer);

export const TooltipContextProvider = ({ children }) => {
  const store = useReducer(wrappedReducer, initialState);
  return (
    <TooltipContext.Provider value={store}>{children}</TooltipContext.Provider>
  );
};

export const useTooltipContext = () => useContext(TooltipContext);


// const Tooltip = ({ width, height, children }) => {
//     const { x, y, show, orientLeft } = useContext(TooltipContext);

//     return (
//         <span
//             transform={`translate(${orientLeft ? x - width : x}, ${y})`}
//             style={{ visibility: show ? "visible" : "hidden" }}
//         >
//             <span width={width} height={height}>
//                 {children}
//             </span>
//         </span>
//     );
// };

// export default Tooltip;
// export { TooltipContext };
