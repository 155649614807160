export const getRole = (role) => {
  switch (role) {
    case '2':
      return 'Analyst';
    case '3':
      return 'Author';
    case '4':
      return 'Reader';
    default:
      return '';
  }
};
