import axios from 'axios';

export const getNotifications = async() => {
  try {
    const response = await axios.get(`/notify/get-notification`);
    return response.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getUnreadNotifications = async() => {
  try {
    const response = await axios.get(`/notify/unread-notification`);
    return response.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const markAsRead = async(params) => {
  try {
    const response = await axios.put(`/notify/read-status`, params);
    return response.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteNotification = async(params) => {
  try {
    const response = await axios.put(`/notify/delete-notification`, params);
    return response.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const archiveNotification = async(params) => {
  try {
    const response = await axios.put(`/notify/archive-notification`, params);
    return response.data.data;
  } catch (error) {
    throw error.response.data;
  }
};
