//fetch all data
const getAllMyTeams = (schema, request) => {
  const qp = request.queryParams;
  const offset = parseInt(qp.page);
  const limit = 10;
  const start = (offset - 1) * limit;
  const end = start + limit;
  const data = schema.db.myteams.slice(start, end);
  const count = schema.myteams.all().length;
  return {
    data,
    pagination: {
      total_records: count,
      currentPage: offset,
      pageSize: limit,
      totalPage: Math.ceil(count / limit)  //(count + limit - 1) / limit
    }

  };

};

//update data
const updateMyTeam = (schema, request) => {
  const attrs = JSON.parse(request.requestBody).team;

  return schema.myteams.find(request.params.id).update(attrs);
};
//add data
const addMyTeam = (schema, request) => {
  const attrs = JSON.parse(request.requestBody).team;

  return schema.myteams.create(attrs);
};
//delete data
const deleteMyTeam = (schema, request) => schema.myteams.find(request.params.id).destroy();


module.exports = {
  getAllMyTeams,
  updateMyTeam,
  addMyTeam,
  deleteMyTeam
};

