import React from 'react';
import { ChevronDown, ChevronUp, CreditCard } from 'react-feather';

import { mergeClasses } from 'classify';
import defaultClasses from './profileDropdown.module.scss';
import { useProfileDropdown } from 'Hooks/Header/useProfileDropdown';
import { useProgressBar } from 'Context/ProgressBar';
import Icon from 'Components/Icon';
import routes from 'Utilities/routesData';

import { Link } from 'react-router-dom';

const ProfileDropdown = (props) => {
  const classes = mergeClasses(defaultClasses, props.classes);
  const { user, Signout } = props;
  const { users, manageSubscription, settings } = routes;
  const [data, { closeProgressBar }] = useProgressBar();
  const { containerRef, handleClick, isOpen } = useProfileDropdown();

  const logout = async() => {
    if (data.files.length) {
      closeProgressBar();
    } else {
      window.location = '/';
      await Signout();
    }
  };

  const _dropdown = isOpen ? (
    <div className={classes.navbar_expand}>
      <h6 className={`${classes.dropdown_header} d-flex align-items-center`}>
        <img
          className={classes.dropdown_user_img}
          // eslint-disable-next-line max-len
          src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2Fd%2Fd8%2FAntu_system-switch-user.svg%2F768px-Antu_system-switch-user.svg.png&f=1&nofb=1"
          alt="profile"
        />
        <div className={classes.dropdown_user_details}>
          <div className={`${classes.dropdown_user_details_name} text-capitalize`}>
            {`${user && user.given_name} ${user && user.family_name}`}
          </div>
          <div className={classes.dropdown_user_details_email}>
            {user?.email || `__`}
          </div>
        </div>
      </h6>
      <div className={classes.dropdown_divider}></div>
      <Link to={`/account/${user.id_key}/${user.tenant_id_key}`} className={`${classes.dropdown_item}`} onClick={handleClick}>
        <div className={classes.dropdown_item_icon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-settings"
          >
            <circle cx="12" cy="12" r="3"></circle>
            {/* eslint-disable-next-line max-len */}
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
        </div>
        Account
      </Link>
      { user['custom:application_role'] === '1' &&
      <Link to={manageSubscription} className={`${classes.dropdown_item}`} onClick={handleClick}>
        <div className={classes.dropdown_item_icon}>
          <Icon src={CreditCard} classes={{root: classes.subscription_icon}}/>
        </div>
        Manage Subscription
      </Link>
      }
      <Link to={settings} className={`${classes.dropdown_item}`} onClick={handleClick}>
        <div className={classes.dropdown_item_icon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-settings"
          >
            <circle cx="12" cy="12" r="3"></circle>
            {/* eslint-disable-next-line max-len */}
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
        </div>
        Settings
      </Link>
      {user['custom:application_role'] !== '4' &&
        <Link className={classes.dropdown_item} to={users} onClick={handleClick}>
          <div className={classes.dropdown_item_icon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-users"
            >
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
          </div>
          Users
        </Link>
      }
      <Link className={classes.dropdown_item} to="#!" onClick={logout}>
        <div className={classes.dropdown_item_icon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-log-out"
          >
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
            <polyline points="16 17 21 12 16 7"></polyline>
            <line x1="21" y1="12" x2="9" y2="12"></line>
          </svg>
        </div>
        Logout
      </Link>
    </div>
  ) : null;

  return (
    <li
      className="nav-item dropdown no-caret mr-2 dropdown-user"
      ref={containerRef}
    >
      <button
        className={`${classes.btn_dropdown} btn ${classes.btn_icon} `}
        onClick={handleClick}
      >
        <img
          className={classes.img_fluid}
          // eslint-disable-next-line max-len
          src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2Fd%2Fd8%2FAntu_system-switch-user.svg%2F768px-Antu_system-switch-user.svg.png&f=1&nofb=1"
          alt="profile"
        />
        <span className="text-capitalize">{`${user && user.given_name} ${user && user.family_name}`}</span>
        <Icon src={isOpen ? ChevronUp : ChevronDown} classes={{root: classes.span_arrow}}/>
      </button>
      {_dropdown}
    </li>
  );
};

export default ProfileDropdown;
