/* eslint-disable max-len */
const login = (schema, request) => {
  const refresh = {token: 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.GogY7DkwbnvJvG1GuD_2msiYWwBTkAnLjtbsZpkMKjZVNEq_jXXCLi-DY7XNDYIp3Sr6TeVO_kVfe_XXirIRZ6UEl0Eo7TRa_1nO8Ny-U_J5pJa6wn7MUdtYJ0-HhlvvRK_dEay9yugA7VMLubMHQzQSXuBFdbFxeIKd9vabIZO-9u88aAHP18C03Swg4VWmAHMI2PPrecAb_BfrRgHfB4Aw920BgLkMAvzVnDSEor0IzlqxQFHuyG9-eI7wRd5yi6dT3ctQWeO6lcNddqNofFbMJic-zSlN-zsieElSV8IKBEat_tD68i7rrKt690p38VaOewJR4bxTEMIrbXPvjQ.OpuzlIf4Wpjy_vJC.8lVC2oAutQjdXrzpAWlL1Ty9lu5wxHib8ugmbMFr1mfCU9mARm_20WA04dmhF-zjuvh4mZdvvOH5F8xoTTlkWeBAimhFHn_4lS0WPYlj4BD2JoeWdcQUvwveRtZgfwDXyLFq7pHB7G1WoFARqi-k9QTiU-A3M4ob_kLbCBEM6MKcXTEaJ7SR4sfJutniBGi1MKbjvyanlwF9pmuO37m_idT-z9IDwVgbIuXbS5q7bYDiN4SWHIxG-ct-BXACV4BCrTmRhltJoMIz-7Q-LIHdfyRZbiA_DDT8X0fe8fadPrNdvSBvjA4h3_Lsr0AaO09Zx8Rf0w15Sm2MpG8BxiXDPNOjymVQ_nqU6tZrgeXz-jDA4yi0ZNX2I4MS4TgYpronC4LEj7RAw9oEclMM63U2g3-eCPT5Ir81sT3mktbGoQgVfb6Rr0d1QP08Q7_MKdShj9FxoKX3AXqToZrbuoJ4__H51VCT9ePfTNZlq5DC8owsFujO7CH4YMla5qtLQnnjHZmEkOsvY7nSPbyaGtmb0JTdH9RTGYUQgCYJ_4rWUCNoxjr-cS7Dy-oMStuCf62ft2yBAyLvvy-eiAYvkrjJ8RaqiCgVvxZOBl8jH5Au1u02TXMleD-SJCwMudIApCdxoQBFdB48-VdUtECHLwFvHOn-KMPR6XsqTTYgFOjyGBsgg3TZMptQygp6z3Sjh5wu9bI-aU50jlSF_BX3H1nmMomAztZwVYjTDb5zfc3MG1ODeTa73dKTrciFkrVRhQenTgLC1Bsl1Vl3mOK1qcMhdVQNXemq8hGuZLNCXv1J5aX6_Z2jFNHWm166Nl8EEtdn6BwGGWooQxvIMwZdJsqoP68c3Te1QEN1t8YaaqqJAeJWM7rUpviEAl4O5mZXj56iEBjxbtwrLnPbCrvWIOESNPxnMpPoJoz5nz3Epcv94d-F458n2X7qtLMQBqhA3c6BzEgN7-24aq0ldEJTocIKr9eiOJR7kuiwN7PUziQfgXgmw-hclRGEbbSkx46p8XOjFaaowkVA2WlVsQQM5LotvsJnPI5bkNHZyUwohgeCouUDcciLe5zlFa3bUy7nnrigMP0wVD6fvCI6iJBqgTJjClnXhG2IC1xPoXC8hzxDAcLX39iFptOcRUT7w_a-f3Gr4xKqwAo5BjJvUMOWfGWP_A50Jm12eLIxW3wrZI0leOmebTNWP_0khLtlP1QFKvQqeBQ066NjpeTpQ3hrAF4i6YHJkKT6LuPCT90uMkVwT5gdMFLjlbdB5xszQEDPC2QWErGyr-urs41_Bb-BxlRtSc_Rvn49t6VlxyY8.lPKRa9yph7cuFV2fbqEimg'};
  const access = 'eyJraWQiOiJ4MFl0OFlva3Jza2lUNHNpeFROQVFidGsrMStBRHpNQlwvN0dMb2YwMUxpUT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJjM2Q4ODY4MC1kMzEyLTQ0OWMtYTNlNy03ZjY4ZDEzNzM0MTIiLCJldmVudF9pZCI6IjU5YjdkMDRjLWQ3Y2ItNDAzZC1iMTU3LWViY2VhYzE1ZDFhMCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MDc1MTIxNzgsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1l2TmV1c0huSiIsImV4cCI6MTYwNzUxMjQ3OCwiaWF0IjoxNjA3NTEyMTc4LCJqdGkiOiI2MzAxNmFkMi1hMzI0LTRlNTgtYTQ1MS1lMzg3YzIwY2VjZWMiLCJjbGllbnRfaWQiOiI3dW9tdTIwbDljbXJlbmF1YXYzb3NnYXNuYiIsInVzZXJuYW1lIjoiYWFrYXNoQG1haWxpbmF0b3IuY29tIn0.j7PaI_oRZ1QZ1MuVSUaaKOUN1PzjhgPNLPeJuh70hEkv6POa4ZjPeDghHFhkIL0FTTsS-DffC_9nD-tX7fkAxwG977O_Oecxu2BvVGsT4bWfiZD3BpcKlAgMrjJYC-duMtEXq0ilRjMxwGS-BBkNWiIeZcu8_UqOCmgvevA_bk5dJAKkx08zrbaFR4Cs7q3QKVXmDmz9mnYlviMChMH3S7EyO-ATnZ1MiT__J0C69nJvBv-1t8DWgJsqF3Q_tj5IJhSi2eQRD0O1VCY0j5bdZWByPRSn5qHKoDjF0uKIPeKpHbD8CtY4zK_eT6qP7jpjLiFCSauqDNINnrsIfXeO_w';
  const token='eyJraWQiOiJKUE8rVE1Fd1RVWUtjdHhmc3hmVGxIaWJJUExBdmlPYmZkSVwvZjMxYjkwaz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJjM2Q4ODY4MC1kMzEyLTQ0OWMtYTNlNy03ZjY4ZDEzNzM0MTIiLCJjdXN0b206dGllciI6IkZyZWUgVGllciIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1l2TmV1c0huSiIsImNvZ25pdG86dXNlcm5hbWUiOiJhYWthc2hAbWFpbGluYXRvci5jb20iLCJjdXN0b206dGVuYW50X2lkIjoiVEVOQU5UNTE5NTQ4YTJmMzEyMDk4NzFkM2MxYTAxOTc1ZmE0YWM3MzhmYTk3NiIsImdpdmVuX25hbWUiOiJmbiIsImF1ZCI6Ijd1b211MjBsOWNtcmVuYXVhdjNvc2dhc25iIiwiZXZlbnRfaWQiOiI1OWI3ZDA0Yy1kN2NiLTQwM2QtYjE1Ny1lYmNlYWMxNWQxYTAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYwNzUxMjE3OCwiZXhwIjoxNjA3NTEyNDc4LCJjdXN0b206cm9sZSI6IlRlbmFudEFkbWluIiwiaWF0IjoxNjA3NTEyMTc4LCJmYW1pbHlfbmFtZSI6ImxuIiwiZW1haWwiOiJhYWthc2hAbWFpbGluYXRvci5jb20ifQ.Wyuym_B0xEm07KcBm2ppViJKdAi5rFaLQkNkE124MDLsXhVw1ntjT4ZlZ89nZrbxmlcdGY3PXMUfu1l3zXKCl3-q0FEws8yPXyXR8sLxTSYaLzTpxYdc6MUqVomhZbJqts3gN7C6fFdnDLxXLiN2lsEmuNAZmpuFdGqiY0Xl1odEGNh75H1tiP2td9gfFNIGs8R_HW4Y8vc3sr18Hw73RsYU4X1VCICrc0FoKOqBTNEORwD_w0JaZfxwM22ag1Uo4KJCKVE5Olq_DqbhcyKiVPPdmOmAKoCQurAfhA2EMfXZ4ucHgTHUuf02cvtLyw0628Qfv-55luKZS5321uRAjQ';

  return {
    refresh,
    access,
    token
  };
};

module.exports = {
  login
};

