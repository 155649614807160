/* eslint-disable camelcase */
import axios from 'axios';

/**
 * Database Action used for creating dataset
 * @param params The data being used to create dataset
 */
export const createDataset = async(params) => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.post(`/dataset`, params);
    return response;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Database Action used to get all dataset associated with user
 * @param id Id of the user
 * @returns {Promise} A collection of datasets
 */
export const getDatasets = async(id, status, params) => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.get(`/datasets/${id}/${status}`, {params});
    return response;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDataset = async(id) => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.get(`/dataset/${id}`);
    return response;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDatasetProjects = async() => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.get(`/dataset/projects`);
    return response.data.data;
  } catch (err) {
    throw err.response.data;
  }
};
/**
 * Database Action used to update dataset
 * @param id Id of the dataset
 * @returns {Promise} A collection of datasets
 */
export const updateDataset = async(id, params) => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.put(`/dataset/${id}`, params);
    return response.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Database Action used to delete a dataset
 * @param id Id of the dataset
 */
export const deleteDataset = async(id, type) => {
  /*eslint no-useless-catch: "error"*/
  try {
    const res = await axios.delete(`/dataset-classification/${id}/${type}`);
    if (res.data.classifications === false) {
      const response = await axios.delete(`/dataset/${id}`);
      return response;
    }
    return res;
  } catch (err) {
    throw err.message;
  }
};

/**
 * Retrieve pre-signed POST data from a dedicated API endpoint
 * @param params The details of the file to be uploaded
 * @returns A signed url which is used for uploading a file
 */
export const getPresignedUrl = async(params) => {

  try {
    const response = await axios.post(`/dataset/getPresignedUrl`, params);
    return response.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Upload file to S3 with previously received pre-signed POST data
 * @param url The api end point
 * @param file The file to be uploaded
 */

export const uploadFile = async(url, params) => {
  try {
    const unauthAxios = axios.create({
      headers: null
    });
    const cancelSource = params.data.cancelSource;
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.round(loaded / total * 100);
        // const percent = Math.floor((loaded * 100) / total);
        params.showProgressBar && params.showProgressBar([{
          progress: percent,
          dataset: params.dataSet,
          name: params.data.name,
          size: params.data.size,
          id: params.id, cancelSource,
          url, fileToUpload:params.data,
          count: params.count,
          fileType: params.sourceFileType
        }]);

        params.getProgress && params.getProgress([{
          progress: percent,
          dataset: params.dataSet,
          name: params.data.name,
          size: params.data.size,
          cancelSource,
          url,
          fileToUpload:params.data,
          isJoin: params.isJoin
        }]);
        // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        // params.setGlobalProgressBar({ loaded, total, percent });
      },
      cancelToken: cancelSource.token
    };

    await unauthAxios.put(url, params.data, options);
    // params.hideProgressBar();
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log(err.message);
    }
    params.cancelUpload({name:params.data.name, join:params.isJoin});
    throw err.message;
  }
};

export const createScheduleRefresh = async(params) => {
  try {
    const res = await axios.post(`/schedule_refresh`, params);
    return res.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateScheduleRefresh = async(params, id) => {
  try {
    const res = await axios.put(`/schedule_refresh/${id}`, params);
    return res.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteScheduleRefresh = async(id) => {
  try {
    const res = await axios.delete(`schedule_refresh/${id}`);
    return res.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getAllscheduleRefresh = async() => {
  try {
    const res = await axios.get(`/schedule_refresh_all`);
    return res.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getConnection = async() => {
  try {
    const res = await axios.get(`/connection`);
    return res.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteConnection = async(id) => {
  try {
    const res = await axios.delete(`/connection/${id}`);
    return res.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const createConnection = async(params) => {
  try {
    const res = await axios.post('/connection', params);
    return res.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateConnection = async(id, params) => {
  try {
    const res = await axios.put(`/connection/${id}`, params);
    return res.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const odbcActionService = async(params) => {
  /* eslint-disable no-useless-catch*/
  try {
    // prod
    // const res = await axios.post(`https://do52c64kve.execute-api.us-east-1.amazonaws.com/prod/dataetl`, params);
    // dev
    const res = await axios.post(`/dataetl`, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const datasetRefreshScheduler = async(params) => {
  try {
    const res = await axios.post(`/datasetRefreshScheduler`, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getTrialDatasets = async(params) => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.get(`/datasets_by_user/99/99`, {params});
    return response;
  } catch (err) {
    throw err.response?.data;
  }
};

export const getApiConnectons = async() => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.get(`/tenant_api_allowed`);
    return response.data.data;
  } catch (err) {
    throw err.response?.data;
  }
};

// to add misc connection by default to the account during first sign in
export const createMiscConnection = async() => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.post(`/tenant_api_allowed`, {api_connection_provider_id: 7});
    return response.data.data;
  } catch (err) {
    throw err.response?.data;
  }
};

export const getRiveryConnections = async() => {
  /*eslint no-useless-catch: "error"*/
  try {
    const response = await axios.get(`/rivery_connection_provider`);
    return response.data.data;
  } catch (err) {
    throw err.response?.data;
  }
};

export const getDatasetCount = async(status) => {
  try {
    const response = await axios.get(`/dataset/count/${status}`);
    return response.data;
  } catch (err) {
    throw err.response?.data;
  }
};
