import React from 'react';

import { mergeClasses } from 'classify';
import defaultClasses from './authLayout.module.scss';

import Header from './header';
import {MiniFooter} from 'Components/Footer';
import { useAppContext } from 'Context/app';

const AuthLayout = (props) => {
  const { children } = props;
  const classes = mergeClasses(defaultClasses, props.classes);
  const [{ is404 }] = useAppContext();
  return (
    <div className={`${classes.rootContainer} d-flex flex-column min-vh-100`}>
      <Header />

      <div className={`${classes.container} p-4 flex-fill ${is404 ? classes.paddingLess : null}`}>{children}</div>

      <MiniFooter />
    </div>
  );
};

export default AuthLayout;
