import React from 'react';

/**
 * My custom context
 */

import AppContextProvider from 'Context/app';
import { ToastContextProvider } from 'Context/Toasts';
import { DailogBoxContextProvider } from 'Context/DailogBox';
import { TooltipContextProvider } from 'Context/Tooltip';
import { ProgressBarContextProvider } from 'Context/ProgressBar';
import { WindowSizeContextProvider } from 'Context/useWindowSize';

const contextProviders = [
  AppContextProvider,
  ToastContextProvider,
  DailogBoxContextProvider,
  TooltipContextProvider,
  WindowSizeContextProvider,
  ProgressBarContextProvider
];

const ContextProvider = ({ children }) =>
  contextProviders.reduceRight(
    (memo, ContextProvider) => <ContextProvider>{memo}</ContextProvider>,
    children
  );

export default ContextProvider;
