

export const usersSeeds = [
  {
    id: 'TM1',
    profilePic: 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png',
    firstName: 'Jeena',
    lastName: 'Thomas'
  },
  {
    id: 'TM2',
    profilePic: 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png',
    firstName: 'Aakash',
    lastName: 'TM'
  },
  {
    id: 'TM3',
    profilePic: 'https://dominicgeorge.in/wp-content/uploads/2020/05/IMG_20180311_210557-scaled-e1590921561124-450x500.jpg',
    firstName: 'Dominic',
    lastName: 'George'
  }
];
