import { useCallback, useEffect, useRef } from 'react';
import { useFieldState, useFormApi } from 'informed';
import { useHistory } from 'react-router-dom';

import { getSearchFromState } from 'Hooks/Dropdown/helpers';

import { useAppContext } from 'Context/app';
import { useFilterState } from 'Hooks/Dropdown/useFilterState';

/**
 * Returns props necessary to render a SearchField component.
 */
export const useSearchField = () => {
  const [{ searchOpen }] = useAppContext();
  const inputRef = useRef();
  const history = useHistory();

  const { value } = useFieldState('search_query');
  const formApi = useFormApi();
  const [filterState] = useFilterState();


  // console.log(value)
  const resetForm = useCallback(() => {
    const nextSearch = getSearchFromState(history.location.search, ['query'], filterState);

    history.push(`${history.location.pathname}${nextSearch}`);
    formApi.reset();
  }, [formApi]);

  // When the search field is opened focus on the input.
  useEffect(() => {
    if (searchOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchOpen]);

  return {
    inputRef,
    resetForm,
    value
  };
};
