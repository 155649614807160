//fetch all data
const getAllMyDashboards = (schema, request) => {
  const qp = request.queryParams;
  const offset = parseInt(qp.page);
  const limit = 10;
  const start = (offset - 1) * limit;
  const end = start + limit;

  const data = schema.db.myDashboards.slice(start, end);
  const count = schema.myDashboards.all().length;
  return {
    data,
    pagination: {
      total_records: count,
      currentPage: offset,
      pageSize: limit,
      totalPage: Math.ceil(count / limit)  //(count + limit - 1) / limit
    }

  };
};


module.exports = {
  getAllMyDashboards
};

