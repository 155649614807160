import React, { Suspense, lazy, memo } from 'react';
import { Switch, Route } from 'react-router-dom';

//import { fullPageLoadingIndicator } from 'Components/LoadingIndicator';

import { PrivateRoute } from './privateRoutes';
import { PublicRoute } from './publicRoutes';
import routes from 'Utilities/routesData';
import { fullPageLoadingIndicator } from 'Components/LoadingIndicator';
import { Modal } from 'Components/Modal';
import defaultClasses from 'Layouts/Main/main.module.scss';

const LoginPage = lazy(() => import('Pages/SignIn'));
const SignUpPage = lazy(() => import('Pages/SignUp'));
const ForgotPassword = lazy(() => import('Pages/ForgotPassword'));
const ResetPassword = lazy(() => import('Pages/ResetPassword'));
const ResetPasswordLogin = lazy(() => import('Pages/NewPassword'));
const MyCdo = lazy(() => import('Pages/MyCdo'));
const Dashboard = lazy(() => import('Pages/Dashboard'));
const Analysis = lazy(() => import('Pages/Analysis'));
const MyProjects = lazy(() => import('Pages/MyProjects'));
const MyAnalysts = lazy(() => import('Pages/MyAnalysts'));
// const MyData = lazy(() => import('Pages/MyData'));
const ListMyData = lazy(() => import('Pages/MyData/ListData'));
const DataLake = lazy(() => import('Pages/DataLake'));
const EditData = lazy(() => import('Pages/MyData/EditData'));
const PreviewData = lazy(() => import('Pages/MyData/PreviewData'));
const MyTeams = lazy(() => import('Pages/MyTeams'));
const Users = lazy(() => import('Pages/Users'));
const Error404 = lazy(() => import('Pages/404'));
const Messages = lazy(() => import('Pages/Messages'));
const CaseStudy = lazy(() => import('Pages/CaseStudy'));
const SupportTips = lazy(() => import('Pages/SupportTips'));
const TermsOfService = lazy(() => import('Pages/TermsOfService'));
const Learn = lazy(() => import('Pages/Learn'));
const RecentNotifications = lazy(() => import('Pages/RecentNotifications'));
const Analyst = lazy(() => import('Pages/Analyst'));
const Bio = lazy(() => import('Pages/Bio'));
const Notifications = lazy(() => import('Pages/Notifications'));
const Payment = lazy(() => import('Pages/Payment'));
const Subscription = lazy(() => import('Pages/Subscription'));
const Invoice = lazy(() => import('Pages/Invoice'));
const ManageSubscription = lazy(() => import('Pages/ManageSubscription'));
const TestDashboard = lazy(() => import('Pages/TestDashboard'));
const Settings = lazy(() => import('Pages/Settings'));

const Routes = () => (

  <Suspense fallback={
    <Modal>
      <aside className={defaultClasses.preload}>
        {fullPageLoadingIndicator}
      </aside>
    </Modal>
  }>
    <Switch>
      <PublicRoute exact path={routes.login} component={LoginPage} />
      <PublicRoute exact path={routes.signUp} component={SignUpPage} />
      <PublicRoute exact path={routes.forgotPassword} component={ForgotPassword} />
      <PublicRoute exact path={routes.resetPassword} component={ResetPassword} />
      <PublicRoute exact path={routes.resetPasswordLogin} component={ResetPasswordLogin} />
      {/* <PublicRoute exact path={routes.caseStudy} component={CaseStudy} /> */}
      <PublicRoute exact path={routes.invoice} component={Invoice} />

      {/* Private Routes */}
      <PrivateRoute exact path={routes.settings} component={Settings} />
      <PrivateRoute exact path={routes.manageSubscription} component={ManageSubscription} />
      <PrivateRoute exact path={routes.subscription} component={Subscription} />
      <PrivateRoute exact path={routes.payment} component= {Payment} />
      <PrivateRoute exact path={routes.myDashboard} component={Dashboard} />
      <PrivateRoute exact path={routes.analysis} component={Analysis} />
      <PrivateRoute exact path={routes.home} component={MyCdo} />
      <PrivateRoute exact path={routes.myProjects} component={MyProjects} />
      <PrivateRoute exact path={routes.myAnalysts} component={MyAnalysts} />
      <PrivateRoute exact path={routes.myTeams} component={MyTeams} />
      <PrivateRoute exact path={routes.users} component={Users} />
      <PrivateRoute exact path={routes.messages} component={Messages} />
      <PrivateRoute exact path={routes.supportTips} component={SupportTips} />
      <PrivateRoute exact path={routes.caseStudy} component={CaseStudy} />
      <PrivateRoute exact path={routes.termsOfService} component={TermsOfService} />
      <PrivateRoute exact path={routes.learn} component={Learn} />
      <PrivateRoute exact path={routes.recentNotifications} component={RecentNotifications} />
      <PrivateRoute exact path={routes.analyst} component={Analyst} />
      <PrivateRoute exact path={routes.account} component={Analyst} />
      <PrivateRoute exact path={routes.bio} component={Bio} />
      <PrivateRoute exact path={routes.notifications} component={Notifications} />
      <PrivateRoute exact path={routes.dataLake} component={DataLake} />
      <PrivateRoute exact path={routes.testDashboard} component={TestDashboard} />

      {/* Mydata routes */}
      <PrivateRoute  exact path={routes.myData} component={ListMyData} />
      <PrivateRoute exact path={routes.myDataClean} component={EditData} />
      <PrivateRoute exact path={routes.myDataPreview} component={PreviewData} />
      <Route component={Error404}  />

    </Switch>
  </Suspense>
);

export default memo(Routes);
