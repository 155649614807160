import React from 'react';
import { Link } from 'react-router-dom';

import { mergeClasses } from 'classify';
import defaultClasses from './header.module.scss';
import NavTrigger from '../SideNavMobile/navTrigger';
import { useHeader } from 'Hooks/Header/useHeader';

const Header = (props) => {
  const classes = mergeClasses(defaultClasses, props.classes);

  const { isFullWidthDashboard, closeDrawer } = useHeader();

  return (
    <nav className={classes.root} style={{
      visibility: props.isHidden ? 'hidden': 'visible'
    }}>
      <NavTrigger />
      <label className={classes.navbar_brand}>
        <Link to='/home' className={classes.navbar_brand} onClick={() => isFullWidthDashboard && closeDrawer()}>
          <img
            className={classes.logo}
            src={require('Assets/logo.png')}
            alt="DashProphet"
          />
        </Link>
      </label>
    </nav>
  );
};

export default Header;
