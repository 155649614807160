//fetch all data
const getAllMyProjects = (schema, request) => {
  const qp = request.queryParams;
  const offset = parseInt(qp.page);
  const limit = 10;
  const start = (offset - 1) * limit;
  const end = start + limit;

  const data = schema.db.myProjects.slice(start, end);
  const count = schema.myProjects.all().length;
  return {
    data,
    pagination: {
      total_records: count,
      currentPage: offset,
      pageSize: limit,
      totalPage: Math.ceil(count / limit)  //(count + limit - 1) / limit
    }

  };
};


//update data
const updateMyProject = (schema, request) => {
  const attrs = JSON.parse(request.requestBody).team;

  return schema.myProjects.find(request.params.id).update(attrs);
};

//add data
const addMyProject = (schema, request) => {
  const attrs = JSON.parse(request.requestBody).team;

  return schema.myProjects.create(attrs);
};

//delete data
const deleteMyProject = (schema, request) => schema.myProjects.find(request.params.id).destroy();


module.exports = {
  getAllMyProjects,
  updateMyProject,
  addMyProject,
  deleteMyProject
};

