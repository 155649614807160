import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { mergeClasses } from 'classify';
import defaultClasses from './header.module.scss';
import routes from 'Utilities/routesData';

const { signUp, login } = routes;

const Header = (props) => {

  const classes = mergeClasses(defaultClasses, props.classes);
  const location = useLocation();

  return (
    <nav className={classes.root}>
      <Link to='/' className={classes.navbar_brand}>
        <img
          className={classes.logo}
          src={require('Assets/logo.png')}
          alt="DashProphet"
        />
      </Link>
      { location.pathname !== '/sign_up' ?
        <span className={classes.navbar_link}>Don’t have an account?<Link to={signUp}>SIGN UP</Link></span>
        : <span className={classes.navbar_link}>Already have an account?<Link to={login}>SIGN IN</Link></span>
      }
    </nav>
  );
};

export default Header;
