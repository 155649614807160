/* eslint-disable max-len */
/**
 * Role Dictionary
 * 0: System Admin
 * 1: Tenant Admin
 * 2: Analyst
 * 3: Author
 * 4: Reader
 */
export const permittedRoutes = {

  '0':['home', 'my_dashboard', 'my_teams', 'my_projects', 'my_data', 'my_analysts', 'messages', 'get_help', 'support_tips', 'case_study', 'terms_of_service', 'learn', 'users', 'recent_notifications', 'analyst', 'account', 'edit_bio', 'analysis', 'manage_subscription', 'notifications', 'test_dashboard', 'settings'],
  '1':['home', 'my_dashboard', 'my_teams', 'my_projects', 'my_data', 'my_analysts', 'messages', 'get_help', 'support_tips', 'case_study', 'terms_of_service', 'learn', 'users', 'recent_notifications', 'analyst', 'account', 'edit_bio', 'analysis', 'manage_subscription', 'notifications', 'test_dashboard', 'settings'],
  '2':['home', 'my_dashboard', 'my_teams', 'my_projects', 'my_data', 'my_analysts', 'messages', 'get_help', 'support_tips', 'case_study', 'terms_of_service', 'learn', 'users', 'recent_notifications', 'analyst', 'account', 'edit_bio', 'analysis', 'notifications'],
  '3':['home', 'my_data', 'my_dashboard', 'analysis', 'notifications'],
  '4':['my_dashboard', 'analysis', 'notifications']
};

export const permittedPaymentRoutes = {
  1: ['subscription'],
  2: ['payment']
};

export const permittedExpiryRoutes = {
  4: ['manage_subscription']
};
