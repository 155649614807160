import { useCallback, useMemo } from 'react';
import { useProgressBarContext } from './useProgressBarContext';
import { useDailogBox } from '../DailogBox';
import useUser from 'Hooks/Account/useUser';


export const useProgressBar = () => {
  const [state, dispatch] = useProgressBarContext() || ['', ''];
  const [, { confirmDailogBox }] = useDailogBox();
  const { user, Signout } = useUser();

  const hideProgressBar = useCallback(
    (e) => {
      dispatch({
        type: 'hide',
        payload: e
      });
    },
    [dispatch]
  );

  const showProgressBar = useCallback(
    (e) => {

      dispatch({
        type: 'show',
        payload: e,
        user
      });

    },
    [dispatch]
  );

  const handleToggle = useCallback(
    (e) => {
      dispatch({
        type: 'toggle',
        payload: e
      });

    },
    [dispatch]
  );

  const handleDatasetToggle = useCallback(
    (e) => {
      dispatch({
        type: 'datasetToggle',
        payload: e
      });

    },
    [dispatch]
  );

  const handleCogButton = useCallback(
    (e) => {
      dispatch({
        type: 'configurationHide',
        payload: e
      });
    },
    [dispatch]
  );

  // const handleConfigure = useCallback(
  //   (e) => {
  //     dispatch({
  //       type: 'datasetConfigure',
  //       payload: e,
  //       toggleDrawer
  //     });

  //   },
  //   [dispatch]
  // );


  const cancelUpload = useCallback((e) => {
    dispatch({
      type: 'cancel',
      payload: e
    });
  });

  const retryUpload = useCallback((e) => {
    dispatch({
      type: 'retry',
      payload: e
    });
  });

  const closeProgressBar = useCallback(
    (e) => {
      dispatch({
        type: 'close',
        payload: e,
        confirmDailogBox,
        Signout
      });
    },
    [dispatch]
  );

  const getProgress = useCallback(
    (e) => {
      dispatch({
        type: 'progress',
        payload: e
      });
    }, [dispatch]
  );

  const deleteFile = useCallback(
    (e) => {
      dispatch({
        type: 'delete',
        payload: e
      });
    }, [dispatch]
  );
  /**
     * The API for managing toasts.
     * Use this API to add and remove toasts.
     *
     * @typedef API
     * @type Object
     */
  const api = useMemo(
    () => ({
      showProgressBar,
      dispatch,
      hideProgressBar,
      handleToggle,
      handleCogButton,
      handleDatasetToggle,
      cancelUpload,
      retryUpload,
      closeProgressBar,
      getProgress,
      deleteFile
    }),
    [showProgressBar, dispatch, getProgress, hideProgressBar, handleCogButton, handleToggle, cancelUpload, retryUpload, closeProgressBar, deleteFile]
  );

  return [state, api];
};
